import { buildQueryString } from '@/helpers/buildQueryString';
import { api } from '@/services/api';
import { ProjectTransactionClientData } from '@/types/Payouts';
import {
  BatchInfo,
  CreateProjectTransactionPayload,
  IsoDateString,
  PayeeType,
  PayoutEventType,
  ProjectDocument,
  ProjectTransactionStatus,
} from 'types';

export const payoutsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query<ProjectDocument, string>({
      query: (projectId) => ({ url: `payouts/projects/${projectId}` }),
      providesTags: (_result, _error, id) => [{ type: 'Projects', id }],
    }),
    getProjectByExternalId: build.query<ProjectDocument, string>({
      query: (externalId) => ({ url: `payouts/projects/external/${externalId}` }),
      providesTags: (_result, _error, id) => [{ type: 'Projects', id }],
    }),
    getProjectTransactions: build.query<
      ProjectTransactionClientData[],
      {
        projectId?: string;
        groupId?: string;
        batchId?: string;
        payeeId?: string;
        projectExternalId?: string; // Finco AccountId
        eventType?: PayoutEventType;
        payeeType?: PayeeType;
        status?: ProjectTransactionStatus; // ProjectTransactionStatus
        programType?: string;
      }
    >({
      query: (args) => ({ url: `payouts/transactions?${buildQueryString(args)}` }),
      providesTags: (_result, _error, _args) => {
        return [{ type: 'ProjectTransactions', id: `LIST` }];
      },
    }),
    getBatches: build.query<
      BatchInfo[],
      { startCutoffDate: IsoDateString; endCutoffDate: IsoDateString; batchReviewStatus: string }
    >({
      query: ({ startCutoffDate, endCutoffDate, batchReviewStatus }) => ({
        url: `payouts/batches/?startCutoffDate=${startCutoffDate}&endCutoffDate=${endCutoffDate}&batchReviewStatus=${batchReviewStatus}`,
      }),
      providesTags: (_result, _error, _args) => {
        return [{ type: 'Batches', id: `LIST` }];
      },
    }),
    getBatchesByReviewStatus: build.query<BatchInfo[], { batchReviewStatus: string }>({
      query: ({ batchReviewStatus }) => ({
        url: `payouts/batches/?batchReviewStatus=${batchReviewStatus}`,
      }),
      providesTags: (_result, _error, _args) => {
        return [{ type: 'Batches', id: `LIST` }];
      },
    }),
    approveBatches: build.mutation<void, { batchIds: string[] }>({
      query: ({ batchIds }) => ({ url: `payouts/batches/approve`, method: 'POST', body: { batchIds } }),
      invalidatesTags: [
        { type: 'ProjectTransactions', id: 'LIST' },
        { type: 'Batches', id: `LIST` },
      ],
    }),
    unbatchTransaction: build.mutation<void, { transactionId: string }>({
      query: ({ transactionId }) => ({ url: `payouts/transactions/${transactionId}/unbatch`, method: 'POST' }),
      invalidatesTags: [
        { type: 'ProjectTransactions', id: 'LIST' },
        { type: 'Batches', id: `LIST` },
      ],
    }),
    addTransactionToBatch: build.mutation<void, { transactionIds: string[] }>({
      query: ({ transactionIds }) => ({
        url: `payouts/transactions/add-to-batch`,
        method: 'POST',
        body: { transactionIds },
      }),
      invalidatesTags: [
        { type: 'ProjectTransactions', id: 'LIST' },
        { type: 'Batches', id: `LIST` },
      ],
    }),
    createProjectTransaction: build.mutation<void, CreateProjectTransactionPayload>({
      query: (data) => ({ url: `payouts/transactions`, method: 'POST', body: data }),
      invalidatesTags: [
        { type: 'ProjectTransactions', id: 'LIST' },
        { type: 'Batches', id: `LIST` },
      ],
    }),
  }),
});

export const {
  useGetProjectQuery,
  useGetProjectByExternalIdQuery,
  useGetProjectTransactionsQuery,
  useApproveBatchesMutation,
  useGetBatchesQuery,
  useGetBatchesByReviewStatusQuery,
  useUnbatchTransactionMutation,
  useAddTransactionToBatchMutation,
  useCreateProjectTransactionMutation,
} = payoutsApi;
