import { FlagPayload, QueueDocumentResponse, QueueNote, UpdateQueue } from 'types';
import { api } from './api';

interface GetQueuesResponse {
  pageNum: number;
  total: number;
  queues: Array<QueueDocumentResponse>;
}

export const queuesApi: any = api.injectEndpoints({
  endpoints: (build) => ({
    getQueues: build.query<GetQueuesResponse, Record<any, any>>({
      query: (queryParams) => {
        return {
          url: `queues${queryParams && '?' + new URLSearchParams({ ...queryParams })}`,
        };
      },
      transformResponse: (response: any) => {
        const { data, pageNum, total } = response || {};
        return {
          pageNum,
          total,
          queues: data,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.queues.map(({ id }: { id: string }) => ({ type: 'Queues' as const, id })),
              { type: 'Queues', id: 'LIST' },
            ]
          : [{ type: 'Queues', id: 'LIST' }],
    }),
    getQueueListFilters: build.query<any, Record<any, any>>({
      query: ({ type }) => {
        return {
          url: `queues/filters?type=${type}`,
        };
      },
    }),
    updateQueue: build.mutation<QueueDocumentResponse, { data: UpdateQueue; id: string }>({
      query: ({ data, id }) => ({
        url: `queues/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: 'Queues', id: result?.id },
        { type: 'Tasks', id: 'LIST' },
        { type: 'Accounts', id: result?.account?.id as unknown as string },
        { type: 'AccountMilestones', id: 'AccountMilestones' },
        { type: 'AccountProgress', id: 'AccountProgress' },
        { type: 'Applications', id: 'LIST' },
        { type: 'Queues', accountId: result?.account?.id },
        { type: 'DocumentDocument', id: 'LIST' },
        { type: 'AccountMilestones', id: result?.account?.id as unknown as string },
      ],
    }),
    addNoteToQueue: build.mutation<QueueDocumentResponse, { data: Pick<QueueNote, 'note'>; id: string }>({
      query: ({ data, id }) => ({
        url: `queues/${id}/notes`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Queues', id: arg?.id }],
    }),
    getQueueByAccountIdAndType: build.query<QueueDocumentResponse, { accountId: string; type: string }>({
      query: ({ accountId, type }) => `accounts/${accountId}/queues/${type}`,
      providesTags: (result, _error, arg) => [
        { type: 'Queues', accountId: arg?.accountId },
        { type: 'Queues', id: result?.id },
      ],
    }),
    getQueuesByAccountId: build.query<QueueDocumentResponse, { accountId: string }>({
      query: ({ accountId }) => `accounts/${accountId}/queues`,
      providesTags: (result, _error, arg) => [
        { type: 'Queues', accountId: arg?.accountId },
        { type: 'Queues', id: result?.id },
      ],
    }),
    getQueuesByAccountIdAndDocumentType: build.query<QueueDocumentResponse, { accountId: string; type: string }>({
      query: ({ accountId, type }) => `accounts/${accountId}/document/${type}/queues`,
      providesTags: (result, _error, arg) => [
        { type: 'Queues', accountId: arg?.accountId },
        { type: 'Queues', id: result?.id },
      ],
    }),
    getQueueById: build.query<QueueDocumentResponse, { id: string }>({
      query: ({ id }) => `queues/${id}`,
      providesTags: (result) => [{ type: 'Queues', id: result?.id }],
    }),
    addFlagToQueue: build.mutation<QueueDocumentResponse, { id: string; flag: FlagPayload }>({
      query: ({ id, flag }) => ({
        url: `queues/${id}/flags`,
        method: 'POST',
        body: flag,
      }),
      invalidatesTags: (result) => [{ type: 'Queues', id: result?.id }],
    }),
    resolveFlag: build.mutation<QueueDocumentResponse, { id: string; dataKey: string; fileName?: string }>({
      query: ({ id, dataKey, fileName }) => ({
        url: `queues/${id}/flags/${dataKey}${fileName ? '?fileName=' + fileName : ''}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: 'Queues', id: result?.id }],
    }),
  }),
});

export const {
  useGetQueuesQuery,
  useUpdateQueueMutation,
  useLazyGetQueuesQuery,
  useAddNoteToQueueMutation,
  useGetQueueByAccountIdAndTypeQuery,
  useGetQueuesByAccountIdAndDocumentTypeQuery,
  useGetQueuesByAccountIdQuery,
  useGetQueueListFiltersQuery,
  useAddFlagToQueueMutation,
  useResolveFlagMutation,
  useGetQueueByIdQuery,
} = queuesApi;
