import { ObjectId } from 'mongodb';
import { ProgramType } from './ProgramTypes';

export enum AdderType {
  arbitrageBattery = 'arbitrageBattery',
  electricalUpgrade = 'electricalUpgrade',
  backupBattery = 'backupBattery',
  backupBatteryKwh = 'backupBatteryKwh',
  backupBatteryPriceCap = 'backupBatteryPriceCap',
  solarReadiness = 'solarReadiness',
}

export enum AdditionalCostAdderType {
  backupBattery = 'backupBattery',
  solarReadiness = 'solarReadiness',
}

export interface AvailableAdders {
  arbitrageBattery?: number;
  backupBattery?: number;
  backupBatteryKwh?: number;
  electricalUpgrade?: number;
  backupBatteryPriceCap?: number;
  solarReadiness?: number;
}

type AdderMap = {
  [key in ProgramType]: {
    [adder in AdderType]?: string;
  };
};

export const adderMap: AdderMap = {
  [ProgramType.solar]: {
    [AdderType.electricalUpgrade]: 'Electrical Upgrade',
    [AdderType.arbitrageBattery]: 'Arbitrage Battery',
    [AdderType.backupBattery]: 'Backup Battery (cost)',
    [AdderType.backupBatteryKwh]: 'Backup Battery ($/kWh)',
    [AdderType.backupBatteryPriceCap]: 'Backup Battery Price Cap',
    [AdderType.solarReadiness]: 'Solar Readiness',
  },
  [ProgramType.doePr]: {
    [AdderType.backupBattery]: 'Backup Battery (cost)',
    [AdderType.solarReadiness]: 'Solar Readiness',
  },
  [ProgramType.hvac]: {},
  [ProgramType.newHomes]: {
    [AdderType.arbitrageBattery]: 'Arbitrage Battery',
    [AdderType.backupBattery]: 'Backup Battery (cost)',
  },
} as const;

export interface AdditionalCostAdder {
  type: AdditionalCostAdderType;
  cost: number;
}

export type AdditionalCostAdders = AdditionalCostAdder[];

export interface AdderDetails {
  type: AdderType;
  quantity?: number;
  model?: string;
  manufacturer?: string;
  cost?: number;
  reasonOptionsSelected?: SolarReadinessAdderOption[];
  otherReasonDetails?: string;
}
export interface AccountAdders {
  accountId: ObjectId;
  adders: AdderDetails[];
}

export type AddersDocument = AccountAdders & {
  id: ObjectId;
};

export interface SolarReadinessAdderOption {
  id: number;
  value: string;
  label: string;
}
