import { Spinner, Box, useTheme } from '@palmetto/palmetto-components';

const LoadingOverlay = ({ isVisible }: any) => {
  const { theme } = useTheme();

  const backgroundColor = theme === 'dark' ? 'rgba(41, 41, 41, 0.656)' : 'rgba(0, 0, 0, 0.064)';

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      width="100%"
      height="100%"
      radius="md"
      zIndex="overlay"
      style={{ background: backgroundColor }}
    >
      <Spinner size="lg" />
    </Box>
  );
};

export default LoadingOverlay;
