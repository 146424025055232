/* eslint-disable max-lines */
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  BackgroundColor,
  BorderColor,
  Box,
  Button,
  CssTextAlignValue,
  FontColor,
  Popover,
  TextInput,
  TextInputSize,
} from '@palmetto/palmetto-components';
import { AppliedDataFilter, DataFilterDisplay, FilterButton, FilterChange } from './FilterButton';

interface AddFilterButtonDropdownProps {
  className?: string;
  size?: TextInputSize;
  dataFilters: DataFilterDisplay[];
  appliedFilters?: AppliedDataFilter[];
  onRemoveFilter: (filterId: string) => void;
  onApplyFilters: (filters: AppliedDataFilter[]) => void;
  onClearFilters: () => void;
}

export const AddFilterButtonDropdown: FC<AddFilterButtonDropdownProps> = ({
  className,
  size = 'sm',
  dataFilters,
  appliedFilters,
  onRemoveFilter,
  onApplyFilters,
  onClearFilters,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [filtersToAdd, setFiltersToAdd] = useState(appliedFilters || []);

  useEffect(() => {
    setFiltersToAdd(appliedFilters || []);
  }, [appliedFilters]);

  const togglePopover = useCallback(() => {
    const prevState = isPopoverOpen;
    setPopoverOpen(!prevState);
  }, [isPopoverOpen]);

  const handleAddFilter = (dataFilter: DataFilterDisplay) => {
    const appliedFilter: AppliedDataFilter = {
      id: dataFilter.id,
      operation: dataFilter.operations[0],
    };

    setPopoverOpen(false);
    setSearchFilterValue('');
    setFiltersToAdd([...filtersToAdd, appliedFilter]);
  };

  const handleChangeFilter = useCallback(
    (change: FilterChange) => {
      const updatedFilters = [...filtersToAdd];
      const existingFilterIndex = updatedFilters.findIndex((filter) => filter.id === change.filterId);

      const newFilter: AppliedDataFilter = {
        ...filtersToAdd[existingFilterIndex],
        operation: change.operation,
        selectedValues: change.selectedValues,
      };

      updatedFilters[existingFilterIndex] = newFilter;
      setFiltersToAdd(updatedFilters);
    },
    [filtersToAdd],
  );

  const handleClickOutside = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  const handleRemoveFilter = useCallback(
    (filterId: string) => {
      onRemoveFilter(filterId);
      const newArray = filtersToAdd.filter((item) => item.id !== filterId);
      setFiltersToAdd(newArray);
    },
    [filtersToAdd, onRemoveFilter],
  );

  const handleClearFilters = useCallback(() => {
    onClearFilters();
    setFiltersToAdd([]);
  }, [onClearFilters]);

  const handleApplyFilters = useCallback(() => {
    onApplyFilters(filtersToAdd);
  }, [filtersToAdd, onApplyFilters]);

  const searchFilterOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilterValue(event.target.value);
  }, []);

  const handleClearSearchFilters = useCallback(() => {
    setSearchFilterValue('');
  }, []);

  const filteredDataFilters = searchFilterValue
    ? dataFilters.filter((dataFilter) => dataFilter.label.toLowerCase().includes(searchFilterValue.toLowerCase()))
    : dataFilters;

  const dataFiltersMap = filteredDataFilters.reduce(
    (accumulator, displayFilter) => {
      accumulator[displayFilter.id] = displayFilter;
      return accumulator;
    },
    {} as Record<string, DataFilterDisplay>,
  );

  const content = (
    <Box display="block" fontSize="sm" overflow="auto" background="secondary" maxHeight="4xl" className={className}>
      <Box>
        <Box padding="xs" background="primary" borderColor="separator" borderWidth="0 0 xs 0">
          <TextInput
            autoFocus
            id="searchStatus"
            size={size}
            label="Search filters"
            placeholder="Search filters"
            hideLabel
            value={searchFilterValue}
            onChange={searchFilterOnChange}
            onClear={handleClearSearchFilters}
          />
        </Box>
        <Box>
          {filteredDataFilters.map((filter) => {
            const { label } = filter;
            const isSelected = appliedFilters?.some((appliedFilter) => appliedFilter.id === filter.id);
            const disable = isSelected || filtersToAdd.includes(filter);

            const filterButtonProps = disable
              ? {
                  className: 'filter-focus',
                  textAlign: 'left' as CssTextAlignValue,
                  borderColor: 'separator' as BorderColor,
                  borderWidth: '0 0 xs 0',
                  color: 'tertiary' as FontColor,
                  padding: 'xs md',
                  background: 'secondary' as BackgroundColor,
                  'data-testid': `${filter.id}-button-disabled`,
                }
              : {
                  as: 'button',
                  className: 'filter-focus',
                  textAlign: 'left' as CssTextAlignValue,
                  borderColor: 'separator' as BorderColor,
                  borderWidth: '0 0 xs 0',
                  color: 'body-primary' as FontColor,
                  cursor: 'pointer',
                  type: 'button',
                  padding: 'xs md',
                  onClick: () => handleAddFilter(filter),
                  background: 'primary' as BackgroundColor,
                  hover: {
                    background: 'info' as BackgroundColor,
                  },
                };

            return (
              <Box key={`${filter.id}-button`} {...filterButtonProps}>
                {label}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );

  const isFiltered = filtersToAdd.length > 0;

  return (
    <Box alignItems="flex-start" childGap="md">
      <Box direction={{ base: 'column', tablet: 'row' }} alignItems={{ base: 'flex-start', tablet: 'center' }} wrap>
        {isFiltered &&
          filtersToAdd.map((filter) => (
            <FilterButton
              key={filter.id}
              appliedFilter={filter}
              dataFilter={dataFiltersMap[filter.id]}
              size={size}
              onRemoveFilter={handleRemoveFilter}
              onFilterChange={handleChangeFilter}
              onApplyFilters={handleApplyFilters}
              startOpen={!appliedFilters?.includes(filter)}
            />
          ))}
        <Popover
          trapFocus
          content={content}
          isOpen={isPopoverOpen}
          placement="bottom-start"
          offsetFromTarget={2}
          contentContainerProps={{
            padding: '0',
            width: '300px',
            shadow: 'lg',
            radius: 'sm',
            overflow: 'hidden',
          }}
          hasArrow={false}
          onClickOutside={handleClickOutside}
          portalTarget={document.body}
          withPortal
        >
          <Button
            onClick={togglePopover}
            variant={isFiltered ? 'tertiary' : 'secondary'}
            tone="neutral"
            size={size}
            iconPrefix="add"
            style={{ whiteSpace: 'nowrap' }}
            className={classNames({
              'shadow-xs': !isFiltered,
              'font-size-sm m-top-xs m-bottom-md m-top-2xs-tablet m-bottom-2xs-tablet m-left-xs': isFiltered,
              'background-color-secondary-100': isPopoverOpen,
            })}
            aria-label="add filter"
          >
            Filter
          </Button>
        </Popover>
        {isFiltered && (
          <Button
            variant="tertiary"
            tone="neutral"
            size={'sm'}
            className="font-size-sm"
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        )}
      </Box>
    </Box>
  );
};
