import { FinancialAccountBank, FinancialAccountDocument } from 'types';
import { api } from './api';

export const paymentMethodsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaymentMethodsByAccountId: build.query<FinancialAccountDocument[], { id: string }>({
      query: ({ id }) => ({
        url: `accounts/${id}/payment-methods`,
        params: { fullResponse: true },
      }),
      providesTags: (_result, _error, { id }) => [{ type: 'FinancialAccountDocument', id }],
    }),
    editPaymentMethodInformation: build.mutation<
      FinancialAccountDocument[],
      { id: string; data: { bankAccount: FinancialAccountBank } }
    >({
      query: ({ id, data }) => ({
        url: `accounts/${id}/payment-method`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'FinancialAccountDocument', id },
        { type: 'Accounts', id },
        { type: 'AccountMilestones', id },
      ],
    }),
  }),
});

export const { useGetPaymentMethodsByAccountIdQuery, useEditPaymentMethodInformationMutation } = paymentMethodsApi;
