import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Icon } from '@palmetto/palmetto-components';
import { MilestoneType } from '../../types/Milestone';
import { PackageRejectionInfo } from 'types';
import { RejectionReasonsList } from './RejectionReasonsList';

const milestoneTypeToLabel: Partial<Record<MilestoneType, any>> = {
  [MilestoneType.noticeToProceed]: 'Notice To Proceed',
  [MilestoneType.install]: 'Install',
  [MilestoneType.activation]: 'Activation',
};

const milestoneLinkMap: {
  [Key in MilestoneType]?: string;
} = {
  [MilestoneType.noticeToProceed]: 'ntp-package/edit',
  [MilestoneType.install]: 'installation-package/edit',
  [MilestoneType.activation]: 'activation-package/edit',
};

interface RejectionBannerProps {
  milestone: MilestoneType;
  rejections: string[];
  className?: string;
  rejectionInfo?: PackageRejectionInfo;
  additionalRejectionContext?: string;
  customViewPathLink?: string;
}

const RejectionBanner = ({
  milestone,
  rejections,
  className,
  rejectionInfo,
  additionalRejectionContext,
  customViewPathLink,
}: RejectionBannerProps) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: any }>();
  const handleViewNavigate = () => {
    if (customViewPathLink) {
      return navigate(customViewPathLink);
    }
    return navigate(`${milestoneLinkMap[milestone]}`);
  };

  const rejectedInfoArray: PackageRejectionInfo[] = rejectionInfo ? Object.values(rejectionInfo) : ([] as any);
  return (
    <Box
      borderWidth="xs"
      borderColor="danger"
      radius="md"
      direction={{ base: 'column', tablet: 'row' }}
      alignItems="stretch"
      fontSize="sm"
      overflow="hidden"
      background="primary"
      shadow="xs"
      className={className}
    >
      <Box
        background="danger"
        padding="lg"
        justifyContent="center"
        direction="row"
        childGap="sm"
        alignItems="center"
      >
        <Icon name="remove" size="2xl" color="danger" />
        <Box fontSize="xl" fontWeight="bold">
          Milestone Rejection
        </Box>
      </Box>
      <Box flex="auto" childGap="2xs" padding="lg" fontSize="md" fontWeight="medium">
        <Box>Milestone: {milestoneTypeToLabel[milestone]}</Box>
        <Box>Rejections: {rejections?.join(', ')}</Box>
        {rejectedInfoArray && rejectedInfoArray?.length > 0 && (
          <Box>
            Reasons:
            {rejectedInfoArray.map((rejectedReasons: PackageRejectionInfo, key: number) => (
              <RejectionReasonsList key={key} rejectionInfo={rejectedReasons} />
            ))}
          </Box>
        )}
        {additionalRejectionContext && <Box>{additionalRejectionContext}</Box>}
      </Box>
      <Box padding={{ base: '0 lg lg lg', tablet: 'lg' }} justifyContent="center">
        <Button
          as="a"
          variant="secondary"
          tone="neutral"
          size={{ base: 'sm', desktop: 'md' }}
          aria-label="view rejected milestone"
          navigate={handleViewNavigate}
        >
          View
        </Button>
      </Box>
    </Box>
  );
};

export default RejectionBanner;
