import { MilestoneType } from './Milestone';

export enum TaskType {
  reviewContract = 'reviewContract',
  reviewUploadedDocument = 'reviewUploadedDocument',
  reviewIdentity = 'reviewIdentity',
  reviewTitle = 'reviewTitle',
  achRequirement = 'achRequirement',
  identityVerification = 'identityVerification',
  titleVerification = 'titleVerification',
  dxfFile = 'dxfFile',
  shadeReport = 'shadeReport',
  planSet = 'planSet',
  permit = 'permit',
  projectSite = 'projectSite',
  roof = 'roof',
  electrical = 'electrical',
  storage = 'storage',
  systemCommissioning = 'systemCommissioning',
  productionModel = 'productionModel',
  monitoringSiteId = 'monitoringSiteId',
  ptoConfirmation = 'ptoConfirmation',
  extendedWarranty = 'extendedWarranty',
  incentives = 'incentives',
  siteImprovements = 'siteImprovements',
  electricianSignOff = 'electricianSignOff',
}

export enum TaskAssigneeType {
  consumer = 'consumer',
  internal = 'internal',
  partner = 'partner',
}

export enum TaskStatus {
  ready = 'ready',
  pending = 'pending',
  completed = 'completed',
  rejected = 'rejected',
  voided = 'voided',
}

export enum NTPTask {
  reviewNTP = 'reviewNTP',
}

export enum InstallationTask {
  reviewInstall = 'reviewInstall',
  dxfFile = 'dxfFile',
  shadeReport = 'shadeReport',
  planSet = 'planSet',
  permit = 'permit',
  projectSite = 'projectSite',
  roof = 'roof',
  electrical = 'electrical',
  storage = 'storage',
  systemCommissioning = 'systemCommissioning',
  productionModel = 'productionModel',
  electricianSignOff = 'electricianSignOff',
  reviewDomesticContent = 'reviewDomesticContent',
}

export enum SystemActivationTask {
  reviewSystemActivation = 'reviewSystemActivation',
  reviewPermissionToOperate = 'reviewPermissionToOperate',
  ptoConfirmation = 'ptoConfirmation',
  extendedWarranty = 'extendedWarranty',
  incentives = 'incentives',
  siteImprovements = 'siteImprovements',
  monitoringSiteId = 'monitoringSiteId',
}

export enum TaskActivityType {
  contract = 'contract',
  requirement = 'requirement',
  review = 'review',
  stipulation = 'stipulation',
}

export enum EntityType {
  accounts = 'accounts',
  accountProgress = 'accountProgress',
  accountTasks = 'accountTasks',
  addressLocationAnomalyLogs = 'addressLocationAnomalyLogs',
  appSettings = 'appSettings',
  applications = 'applications',
  contracts = 'contracts',
  contractDocumentSettings = 'contractDocumentSettings',
  creditCheckLogs = 'creditCheckLogs',
  disclosures = 'disclosures',
  documents = 'documents',
  events = 'events',
  financeProducts = 'financeProducts',
  organizations = 'organizations',
  organizationSettings = 'organizationSettings',
  prequalifications = 'prequalifications',
  products = 'products',
  projects = 'projects',
  propertyVerificationLogs = 'propertyVerificationLogs',
  quotes = 'quotes',
  systemDesigns = 'systemDesigns',
  tasks = 'tasks',
  users = 'users',
  webhookClients = 'webhookClients',
  webhookLogs = 'webhookLogs',
  webhookSubscriptions = 'webhookSubscriptions',
}

export interface TaskReferenceIdObject {
  id: string;
  type: EntityType;
}

export enum TaskRejectionReason {
  documentRejected = 'documentRejected',
  moreInformationNeeded = 'moreInformationNeeded',
  notApplicable = 'notApplicable',
  tryAgain = 'tryAgain',
  notCompleted = 'Not Completed',
  missingDocumentation = 'Missing Documentation',
  illegibleDocumentation = 'Illegible Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  codeViolation = 'Code Violation',
  poorWorkmanship = 'Poor Workmanship',
}

export interface RejectionReasons {
  reasons?: TaskRejectionReason[];
  note?: string;
  rejectedAt: Date;
  rejectedBy: string | undefined; // auth0id == userId
}

export interface Task {
  activityType: TaskActivityType;
  assigneeType: TaskAssigneeType;
  assignedTo: string; // auth0id == userId
  completedAt?: Date;
  createdAt?: Date;
  description: string;
  id: string;
  name: string;
  referenceIds: TaskReferenceIdObject[];
  rejectionReasons?: RejectionReasons[];
  startedAt?: Date;
  status: TaskStatus;
  type: TaskType;
  milestone?: MilestoneType;
}

export const taskToMilestoneMap: {
  [Key in TaskType]?: MilestoneType;
} = {
  [TaskType.dxfFile]: MilestoneType.installPackageComplete,
  [TaskType.shadeReport]: MilestoneType.installPackageComplete,
  [TaskType.planSet]: MilestoneType.installPackageComplete,
  [TaskType.permit]: MilestoneType.installPackageComplete,
  [TaskType.projectSite]: MilestoneType.installPackageComplete,
  [TaskType.roof]: MilestoneType.installPackageComplete,
  [TaskType.electrical]: MilestoneType.installPackageComplete,
  [TaskType.storage]: MilestoneType.installPackageComplete,
  [TaskType.systemCommissioning]: MilestoneType.installPackageComplete,
  [TaskType.productionModel]: MilestoneType.installPackageComplete,
  [TaskType.ptoConfirmation]: MilestoneType.systemActivation,
  [TaskType.extendedWarranty]: MilestoneType.systemActivation,
  [TaskType.incentives]: MilestoneType.systemActivation,
  [TaskType.siteImprovements]: MilestoneType.systemActivation,
  [TaskType.monitoringSiteId]: MilestoneType.systemActivation,
} as const;
