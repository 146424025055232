import { Helmet } from 'react-helmet';
import { MainContainer } from '..';
import { FilterableProjectTransactionTable } from '@/components/Payouts/FilterableProjectTransactionTable';
import { useParams } from 'react-router-dom';
import { useGetProjectByExternalIdQuery, useGetProjectTransactionsQuery } from '@/services/payouts';
import { AccountFundingSummary } from '@/components/Account/AccountFundingSummary';
import { Alert, Box } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { MilestoneType } from '@/types/Milestone';
import { AccountMilestoneStatus, PayeeType, PayoutEvent, PayoutEventType } from 'types';

export const AccountFundingDetails = ({ account }: { account: any }) => {
  const { id = '' } = useParams<{ id: string }>();
  const { data: transactions, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    projectExternalId: id,
  });
  const { data: project, isLoading: isProjectLoading } = useGetProjectByExternalIdQuery(id);
  const isLoading = isTransactionsLoading || isProjectLoading;
  const showNoInstallPayoutWarning = useMemo(() => {
    if (isTransactionsLoading || !transactions || !account) return false;
    const isCed = account.ced?.spaPricingEnabled || account.ced?.cedSplitPayEnabled;
    // If the account is not CED, we don't need to show the warning
    if (!isCed) return false;
    const hasCedVendorInvoiceTransaction = transactions?.find(
      (t) =>
        t.event === PayoutEvent.invoice &&
        t.eventType === PayoutEventType.materials &&
        t.payeeType === PayeeType.vendor,
    );
    // If there is a materials invoice transaction, we don't need to show the warning
    if (hasCedVendorInvoiceTransaction) return false;

    // determine by milestone if the alert should be displayed
    const installMilestone = account.milestones?.find((m: any) => m.type === MilestoneType.install);
    const isInstallMilestoneApproved =
      installMilestone && installMilestone?.status === AccountMilestoneStatus.approved;
    const isInstallMilestoneSubmitted =
      installMilestone && installMilestone?.status === AccountMilestoneStatus.submitted;

    const activationMilestone = account.milestones?.find((m: any) => m.type === MilestoneType.activation);
    const isActivationMilestoneApproved =
      activationMilestone && activationMilestone?.status === AccountMilestoneStatus.approved;
    return isInstallMilestoneApproved || isInstallMilestoneSubmitted || isActivationMilestoneApproved;
  }, [account, transactions, isTransactionsLoading]);

  return (
    <>
      <Helmet>
        <title>Account Funding</title>
      </Helmet>
      <MainContainer maxWidth="100" padding="0 4xl" gap="sm">
        {!isLoading && transactions && project && (
          <>
            {showNoInstallPayoutWarning && (
              <Alert
                variant="warning"
                hasIcon
                title="Payment for this account cannot be issued until CED material invoices are received. Please reach out to CED to finalize the invoice."
              />
            )}
            <AccountFundingSummary transactions={transactions} project={project} />
            <FilterableProjectTransactionTable
              transactions={transactions}
              hideColumns={['projectName']}
              hideSummary
            />
          </>
        )}
        {!isLoading && (!transactions || !project) && (
          <Box textAlign={'center'}>No funding exists for this account.</Box>
        )}
      </MainContainer>
    </>
  );
};
