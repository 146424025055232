import { Box, Button, Modal, useOpenClose } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from '../../services/accounts';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../auth/requirePermissions';
import { AccountMilestone, CancelActivityRequesterType, CancelReason, MilestoneType } from 'types';
import { CancelReactivateAccountModal } from './CancelReactivateAccountModal';

interface CancelAccountProps {
  handleClose: () => void;
  accountData: any;
  accountId: string;
  showNoCancelAfterInstall: boolean;
  title: string;
}

export const cancelReasonLabels = {
  [CancelReason.financialConcerns]: 'Financial Concerns',
  [CancelReason.ineligiblePropertyType]: 'Ineligible Property Type',
  [CancelReason.choseDifferentFinancialProvider]: 'Chose Different Financial Provider',
  [CancelReason.unhappyWithSalesProcess]: 'Unhappy With Sales Process',
  [CancelReason.reasonNotProvided]: 'Reason Not Provided',
};

export const cancelActivityRequesterTypeLabels = {
  [CancelActivityRequesterType.organization]: 'Organization',
  [CancelActivityRequesterType.customer]: 'Customer',
  [CancelActivityRequesterType.internal]: 'LightReach',
};

const CancelNotAllowedModal = ({ handleClose }: Pick<CancelAccountProps, 'handleClose'>) => {
  return (
    <>
      <Modal.Body background="secondary">
        <Box gap="lg">
          <Box as="p">This account cannot be cancelled because the install package has been submitted.</Box>
          <Box as="p">If you need assistance, please contact support.</Box>
          <Button
            tone="neutral"
            href="https://help.palmetto.finance/"
            variant="primary"
            as="a"
            style={{ width: 'fit-content' }}
            iconSuffix="launch-app"
          >
            Visit help.palmetto.finance
          </Button>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" tone="neutral" size="md" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};

const ReactivateAccount = () => {
  const { id } = useParams<{ id: any }>();
  const { data: accountData, isLoading }: any = useGetAccountQuery(id);
  const { isOpen: isModifyOpen, handleClose, handleToggle } = useOpenClose();
  const { claims } = useAuth();
  const canCancelAfterInstall = isPalmettoFinanceUser(claims);
  const installIsCompleted = Boolean(
    accountData?.milestones.find((m: AccountMilestone) => m.type === MilestoneType.install)?.completed,
  );
  const showNoCancelAfterInstall = installIsCompleted && !canCancelAfterInstall;
  const title = showNoCancelAfterInstall ? 'Cancellation Not Allowed' : 'Cancel Account';

  return (
    <>
      <Modal
        isOpen={isModifyOpen}
        onDismiss={() => {
          handleClose();
        }}
        maxWidth="4xl"
        ariaLabelledBy="cancelAccountHeader"
        fullScreenMobile
        overflow="scroll"
      >
        {showNoCancelAfterInstall && (
          <Modal.Header
            id="cancelAccountHeader"
            title={title}
            onDismiss={() => {
              handleClose();
            }}
          />
        )}
        {showNoCancelAfterInstall ? (
          <CancelNotAllowedModal
            handleClose={() => {
              handleClose();
            }}
          />
        ) : (
          <CancelReactivateAccountModal
            handleClose={() => {
              handleClose();
            }}
            accountData={accountData || {}}
            accountId={id}
            showNoCancelAfterInstall={showNoCancelAfterInstall}
            title={title}
            isReactivation
          />
        )}
      </Modal>

      {!isLoading && (
        <RequirePermissions permissions={['admin']}>
          <Button
            tone="primary"
            variant="secondary"
            size="sm"
            style={{ width: 'fit-content' }}
            onClick={handleToggle}
          >
            Reactivate
          </Button>
        </RequirePermissions>
      )}
    </>
  );
};

export default ReactivateAccount;
