import { Badge, Box, Button, Modal } from '@palmetto/palmetto-components';
import { SalesRep } from 'types';

type SalesRePActionModalProps = {
  isModalOpen: boolean;
  salesRep: SalesRep & { actionToPerform: string };
  handleCloseModal: () => void;
  handleUpdateStatus: () => void;
  isLoading: boolean;
};
export const SalesRepActionModal = ({
  isModalOpen,
  salesRep,
  handleCloseModal,
  handleUpdateStatus,
  isLoading,
}: SalesRePActionModalProps) => {
  return (
    <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={handleCloseModal} maxWidth="5xl">
      <Modal.Header
        id="titleBasic"
        title={`${salesRep?.actionToPerform?.charAt(0)?.toUpperCase() + salesRep?.actionToPerform?.slice(1)} Sales Rep`}
        onDismiss={handleCloseModal}
      />
      <Modal.Body childGap="md" background="secondary">
        <Box gap="xs">
          <Box display="flex" direction="row" gap="xs">
            <Box fontWeight="medium">ReCheck Id:</Box> {salesRep?.idProviderUser?.idProviderId}
          </Box>
          <Box display="flex" direction="row" gap="xs">
            <Box fontWeight="medium">Name:</Box> {salesRep?.idProviderUser?.displayName}
          </Box>
          <Box display="flex" direction="row" gap="xs">
            <Box fontWeight="medium">Email:</Box> {salesRep?.idProviderUser?.emails?.[0]}
          </Box>
        </Box>
        <Box gap="xs" fontSize="md" display="flex" direction="row" alignContent="center">
          <Box fontWeight="medium">Current Status:</Box>{' '}
          <Badge
            variant={
              salesRep?.status === 'active' ? 'success' : salesRep?.status === 'blocked' ? 'danger' : 'warning'
            }
            message={salesRep?.status}
          />
        </Box>
        <Box as="p">
          Confirm that you want to {salesRep?.status === 'active' ? 'ADD' : 'REMOVE'} this ReCheck ID{' '}
          {salesRep?.status === 'active' ? 'to' : 'from'} the LightReach Global Block List.
        </Box>
      </Modal.Body>
      <Modal.Footer justifyContent="space-between">
        <Button onClick={handleCloseModal} variant="secondary" tone="neutral">
          Cancel
        </Button>
        <Button
          variant="primary"
          className="shadow-xs"
          onClick={handleUpdateStatus}
          style={{ whiteSpace: 'nowrap' }}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
