import { Box } from '@palmetto/palmetto-components';
import {
  ProjectTransactionsFilter,
  ProjectTransactionSort,
  transactionSortOptions,
} from '@/components/Payouts/ProjectTransactionsFilter';
import { ProjectTransactionTable } from '@/components/Payouts/ProjectTransactionTable';
import { useCallback, useMemo, useState } from 'react';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { ProjectTransactionClientData } from '@/types/Payouts';
import { AppliedDataFilter, DataFilterOperation } from '@/components/filters/FilterButton';
import { useSearchParams } from 'react-router-dom';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';

interface FilterableProjectTransactionTableProps {
  transactions: ProjectTransactionClientData[];
  handleRowSelected?(ids: string[], selected: boolean): void;
  selectedIds?: string[];
  exportUrl?: string;
  /** Columns to hide, will also hide the filter and sort for that data field */
  hideColumns?: string[];
  hideSummary?: boolean;
}

export const FilterableProjectTransactionTable = ({
  transactions,
  selectedIds,
  handleRowSelected,
  exportUrl,
  hideColumns,
  hideSummary = false,
}: FilterableProjectTransactionTableProps) => {
  const [appliedFilters, setAppliedFilters] = useState<AppliedDataFilter[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const advancedFilters = searchParams.get('advancedFilters') ?? '';
  const searchTerm = searchParams.get('search') || undefined;
  const sort = (searchParams.get('sort') as any) || 'eventDateAsc';
  const appliedSort = sort ? transactionSortOptions.find((opt) => opt.id === sort) : transactionSortOptions[0];
  const displayTransactions = useMemo(
    () =>
      transactions
        .filter((transaction) =>
          appliedFilters.every((filter) =>
            filter.operation === DataFilterOperation.Equal
              ? filter.selectedValues?.some(
                  (selectedValue) => `${(transaction as any)[filter.id]}` === selectedValue,
                )
              : !filter.selectedValues?.includes((transaction as any)[filter.id]),
          ),
        )
        .filter(
          (transaction) =>
            !searchTerm ||
            Object.values(transaction).some((value) => {
              if (typeof value === 'string') {
                return value.toLowerCase().includes(searchTerm.toLowerCase());
              }
            }),
        )
        .sort((a, b) => {
          switch (sort) {
            case 'payeeNameAsc':
              return a.payeeName?.localeCompare(b.payeeName);
            case 'payeeNameDesc':
              return b.payeeName?.localeCompare(a.payeeName);
            case 'amountAsc':
              return a.amount - b.amount;
            case 'amountDesc':
              return b.amount - a.amount;
            case 'eventDateAsc':
              return a.eventDate < b.eventDate ? -1 : a.eventDate > b.eventDate ? 1 : 0;
            case 'eventDateDesc':
              return b.eventDate < a.eventDate ? -1 : b.eventDate > a.eventDate ? 1 : 0;
            case 'projectNameAsc':
              return a.projectName?.localeCompare(b.projectName);
            case 'projectNameDesc':
              return b.projectName?.localeCompare(a.projectName);
            case 'eventAsc':
              return a.event?.localeCompare(b.event);
            case 'eventDesc':
              return b.event?.localeCompare(a.event);
            default:
              return 0;
          }
        }),
    [transactions, appliedFilters, appliedSort, searchTerm],
  );

  const handleSortSelection = useCallback(
    (sortSelection: { id: string; label: string }) => {
      searchParams.set('sort', sortSelection.id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const totalPayoutAmount = useMemo(
    () => displayTransactions.reduce((acc, transaction) => acc + transaction.amount, 0),
    [displayTransactions],
  );

  const totalPayees = useMemo(() => {
    const payeeIds = displayTransactions.map((transaction) => transaction.payeeId);
    return Array.from(new Set(payeeIds)).length;
  }, [displayTransactions]);

  return (
    <Box gap="md" background="primary" radius="md" padding="md 0">
      <ProjectTransactionsFilter
        transactions={transactions}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        searchTerm={searchTerm}
        advancedFilters={advancedFilters}
        handleSortSelection={handleSortSelection}
        appliedSort={appliedSort}
        exportUrl={exportUrl}
        hideSortOptions={[
          ...(hideColumns?.map((column) => [`${column}Asc`, `${column}Desc`] as ProjectTransactionSort[])?.flat() ??
            []),
        ]}
        hideFilterOptions={hideColumns ?? []}
      />
      {!hideSummary && (
        <Box padding="md 0" alignItems="flex-end">
          <Box direction="row" childGap="md" padding="0 md">
            <Box direction="column" alignItems="flex-end">
              <RequirePalmettoFinanceUser>
                <Box fontSize="lg" fontWeight="medium" padding="0 0 xs 0">
                  {totalPayees}
                </Box>
                Payees
              </RequirePalmettoFinanceUser>
            </Box>
            <Box direction="column" alignItems="flex-end" padding="0 md">
              <Box fontSize="lg" fontWeight="medium" padding="0 0 xs 0">
                {displayTransactions.length}
              </Box>
              Transactions
            </Box>
            <Box direction="column" alignItems="flex-end" padding="0 0 0 md">
              <Box fontSize="lg" fontWeight="medium" padding="0 0 xs 0">
                {currencyFormatter.format(totalPayoutAmount)}
              </Box>
              Total Payout Amount
            </Box>
          </Box>
        </Box>
      )}
      <ProjectTransactionTable
        transactions={displayTransactions}
        handleRowSelected={handleRowSelected}
        selectedIds={selectedIds}
        hideColumns={hideColumns}
      />
    </Box>
  );
};
