import { Box, Popover, useOpenClose, Badge } from '@palmetto/palmetto-components';
import { PayoutEvent, PayoutEventMap, PayoutEventType } from 'types';
import { useMemo } from 'react';

interface TransactionStatusBadgeProps {
  event: PayoutEvent;
  description: string;
  eventType: PayoutEventType;
}

const flatLabelMap = Object.values(PayoutEventMap).flat();

export const TransactionEventBadge = ({ event, description, eventType }: TransactionStatusBadgeProps) => {
  const { isOpen, handleClose, handleOpen } = useOpenClose();
  const badge = useMemo(() => {
    if (eventType === PayoutEventType.manual) {
      return <Badge variant="primary" message="Manual Payment" />;
    } else if (eventType === PayoutEventType.clawback) {
      return <Badge variant="danger" message="Clawback" />;
    } else if (eventType === PayoutEventType.materials) {
      const message =
        event === PayoutEvent.estimate
          ? 'Materials Estimate'
          : event === PayoutEvent.invoice
            ? 'Materials Invoice'
            : 'Unknown Event';
      return <Badge variant={`${event === PayoutEvent.invoice ? 'primary' : 'secondary'}`} message={message} />;
    } else {
      const labelItem = flatLabelMap.find((item) => item.event === event);
      return <Badge variant={labelItem?.badgeVariant || 'default'} message={labelItem?.name || 'Unknown Event'} />;
    }
  }, []);

  return description ? (
    <Popover
      content={<Box padding="xs">{description}</Box>}
      isOpen={isOpen}
      placement="top"
      onClickOutside={handleClose}
      withPortal
      portalTarget={document.body}
    >
      <Box onMouseOver={handleOpen} onMouseOut={handleClose} direction="row">
        {badge}
      </Box>
    </Popover>
  ) : (
    badge
  );
};
