import { ObjectId } from 'mongodb';
import { AdderType, AdditionalCostAdder, AdditionalCostAdders } from './Adders';
import { FinanceType } from './ProductPricing';
import { IPricingSettingsDocument } from './AppSettings';
import { ProgramType } from './ProgramTypes';

export interface AnnualPricingDetails {
  year: number;
  monthlyPayment: number;
  estimatedAnnualProduction: number;
  guaranteedAnnualProduction: number;
  yearlyCost: number;
  kwhRate: number;
  backupBatteryPayment?: number;
  totalMonthlyPayment: number;
  totalYearlyCost: number;
}

export enum QuoteStatus {
  active = 'active',
  contracted = 'contracted',
  voided = 'voided',
}

export enum BackupBatteryPricingMethodOption {
  embedded = 'embedded', // Solely used for the DOE PR program.
  kwhRate = 'kwhRate',
  rawCost = 'rawCost',
}

export interface QuoteDocument {
  id: ObjectId;
  accountId: ObjectId;
  productId: ObjectId;
  externalReference?: string;
  type: FinanceType;
  productName: string;
  systemFirstYearProductionKwh: number;
  systemSizeKw: number;
  kwhPrice: number;
  escalationRate?: number;
  pricingSettings: IPricingSettingsDocument['value'];
  preConAdderCost?: number;
  totalSystemCost: number;
  pricePerWatt: number;
  systemPricingDetails: Array<AnnualPricingDetails>;
  totalAmountPaid: number;
  status: QuoteStatus;
  adders?: Array<AdderType>;
  priceSheetMappingId?: ObjectId;
  actualPricePerWatt: number;
  actualTotalSystemCost: number;
  additionalCostAdders?: AdditionalCostAdders;
  backupBatteryDetails?: {
    pricingMethod?: BackupBatteryPricingMethodOption;
    manufacturer?: string;
    modelNumber?: string;
    quantity?: number;
  };
  globalPpwModifier?: number;
  orgEnergyCommunityModifierId?: ObjectId;
  orgHoldbackId?: ObjectId;
  orgPpwModifierId?: ObjectId;
  orgDomesticContentModifierId?: ObjectId;
  totalSystemCostWithoutDomesticContent?: number;
  pricePerWattWithoutDomesticContent?: number;
  actualTotalSystemCostWithoutDomesticContent?: number;
  actualPricePerWattWithoutDomesticContent?: number;
  domesticContentEligible?: boolean;
}

export interface CreateQuoteRequest {
  productId: string;
  externalReference?: string;
  arbitrageBatteryIncluded?: boolean;
  backupBatteryIncluded?: boolean;
  backupBatteryKwhIncluded?: boolean;
  electricalUpgradeIncluded?: boolean;
  backupBatteryCost?: number;
  backupBatteryManufacturer?: string;
  backupBatteryModelNumber?: string;
  backupBatteryQuantity?: number;
  includeDomesticContentPricing?: boolean;
}

export interface CreateDoePrQuoteRequest {
  productId: string;
  externalReference?: string;
  backupBatteryManufacturer?: string;
  backupBatteryModelNumber?: string;
  backupBatteryQuantity?: number;
  solarReadinessCost?: number;
}

export interface CreateHvacQuoteRequest {
  productId: string;
  externalReference?: string;
  totalFinancedAmount: number;
}

export interface HvacPricingDetails {
  year: number;
  monthlyPayment: number;
  yearlyCost: number;
}
export interface HVACQuoteDocument {
  id: ObjectId;
  accountId: ObjectId;
  productId: ObjectId;
  externalReference?: string;
  type: FinanceType;
  productName: string;
  escalationRate: number;
  totalSystemCost: number;
  systemPricingDetails: HvacPricingDetails[];
  totalAmountPaid: number;
  status: QuoteStatus;
  priceSheetMappingId?: ObjectId;
}

export type Quote = {
  id: string;
  accountId: string;
  productId: string;
  externalReference?: string;
  type?: FinanceType;
  systemFirstYearProductionKwh: number;
  systemSizeKw: number;
  kwhPrice: number;
  totalSystemCost: number;
  productName: string;
  pricePerWatt: number;
  preConAdderCost?: number;
  systemPricingDetails: Array<AnnualPricingDetails>;
  totalAmountPaid: number;
  status: QuoteStatus;
  contractId: string;
  adders?: Array<string>;
  additionalCostAdders: Array<AdditionalCostAdder>;
  backupBatteryDetails?: {
    pricingMethod?: BackupBatteryPricingMethodOption;
    manufacturer?: string;
    modelNumber?: string;
    quantity?: number;
  };
  programType: ProgramType;
  totalSystemCostWithoutDomesticContent: number;
  pricePerWattWithoutDomesticContent: number;
  domesticContentEligible: boolean;
};
