import { ObjectId } from 'mongodb';
import { ProgramType } from './ProgramTypes';
import { AttestmentData, DomesticContentAttestmentData } from './Attestment';

export enum PanelOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  combination = 'combination',
}

export enum MountingType {
  ground = 'ground',
  roof = 'roof',
  combination = 'combination',
}

export interface MonthlyProduction {
  month: number;
  productionKwh: number;
}

export interface MonthlySolarAccess {
  month: number;
  solarAccess: number;
}

export interface SystemAsset {
  manufacturer: string;
  model: string;
  count: number;
}

export interface SystemArray {
  panelManufacturer: string;
  panelModel: string;
  panelCount: number;
  panelWattage: number;
  orientation: PanelOrientation;
  tilt: number;
  azimuth: number;
  annualTSRF: number;
  size: number;
  monthlySolarAccess: {
    month: number;
    accessPercent: number;
  }[];
  inverter: string;
}

export interface ToleranceException {
  note?: string;
  exceptionGivenBy: string;
}

/** @deprecated */
interface SystemDesignDocumentDeprecated {
  /** @deprecated */
  inverterManufacturer?: string;
  /** @deprecated */
  inverterModel?: string;
  /** @deprecated */
  inverterCount?: number;
  /** @deprecated */
  mountingModel?: string;
  /** @deprecated */
  totalMountingCount?: number;
  /** @deprecated */
  storageManufacturer?: string;
  /** @deprecated */
  storageModel?: string;
  /** @deprecated */
  totalStorageCount?: number;
}

export interface SystemDesign extends SystemDesignDocumentDeprecated {
  accountId: ObjectId;
  isCurrent: boolean;
  isApproved?: boolean;
  systemFirstYearProductionKwh: number;
  systemSizeKw: number;
  inverters?: Partial<SystemAsset>[] | [];
  panelManufacturer?: string;
  panelModel?: string;
  totalPanelCount?: number;
  arrays?: Partial<SystemArray>[] | [];
  firstYearMonthlyProduction?: Partial<MonthlyProduction>[] | [];
  mountingType?: string;
  storage?: Partial<SystemAsset>[] | [];
  totalStorageCapacityKwh?: number;
  inverterManufacturer?: string;
  inverterModel?: string;
  inverterCount?: number;
  firstYearMonthlyProductionKwh?: Array<Partial<MonthlyProduction>> | [];
  mountingManufacturer?: string;
  dateCreated: Date;
  toleranceException?: ToleranceException | null;
  attestment?: AttestmentData | null;
  domesticContentAttestment?: DomesticContentAttestmentData | null;
}

export enum HVACEquipmentType {
  heatPump = 'heatPump',
  airHandler = 'airHandler',
  heatStrip = 'heatStrip',
  thermostat = 'thermostat',
  furnace = 'furnace',
  waterHeater = 'waterHeater',
  variableSpeedHeatPump = 'variableSpeedHeatPump',
  evaporatorCoil = 'evaporatorCoil',
  ductlessOutdoor = 'ductlessOutdoor',
  ductlessIndoor = 'ductlessIndoor',
  packagedUnit = 'packagedUnit',
  accessories = 'accessories',
  airCleaner = 'airCleaner',
  airFilter = 'airFilter',
  coilCasing = 'coilCasing',
  dehumidifier = 'dehumidifier',
  ductlessControls = 'ductlessControls',
  humidifier = 'humidifier',
  txvKit = 'txvKit',
  ventilator = 'ventilator',
  zoning = 'zoning',
  other = 'other',
}

export const HVACEquipmentTypeLabels: Record<HVACEquipmentType, string> = {
  [HVACEquipmentType.airHandler]: 'Air Handler',
  [HVACEquipmentType.heatPump]: 'Heat Pump',
  [HVACEquipmentType.heatStrip]: 'Heat Strip',
  [HVACEquipmentType.thermostat]: 'Thermostat',
  [HVACEquipmentType.furnace]: 'Furnace',
  [HVACEquipmentType.waterHeater]: 'Water Heater',
  [HVACEquipmentType.variableSpeedHeatPump]: 'Variable Speed Heat Pump',
  [HVACEquipmentType.evaporatorCoil]: 'Evaporator Coil',
  [HVACEquipmentType.ductlessOutdoor]: 'Ductless Outdoor',
  [HVACEquipmentType.ductlessIndoor]: 'Ductless Indoor',
  [HVACEquipmentType.packagedUnit]: 'Packaged Unit',
  [HVACEquipmentType.accessories]: 'Accessories',
  [HVACEquipmentType.airCleaner]: 'Air Cleaner',
  [HVACEquipmentType.airFilter]: 'Air Filter',
  [HVACEquipmentType.coilCasing]: 'Coil Casing',
  [HVACEquipmentType.dehumidifier]: 'Dehumidifier',
  [HVACEquipmentType.ductlessControls]: 'Ductless Controls',
  [HVACEquipmentType.humidifier]: 'Humidifier',
  [HVACEquipmentType.txvKit]: 'TXV Kit',
  [HVACEquipmentType.ventilator]: 'Ventilator',
  [HVACEquipmentType.zoning]: 'Zoning',
  [HVACEquipmentType.other]: 'Other',
} as const;

export interface HVACEquipmentDetails {
  type: HVACEquipmentType;
  name: string;
  manufacturer: string;
  model: string;
  quantity: number;
  cost?: number;
  productId?: string;
  serialNumber?: string;
}

export interface HVACInstallKitDetails {
  name: string;
  quantity: number;
  cost?: number;
}

export interface HVACServiceDetails {
  name: string;
  cost?: number;
  quantity: number;
}

export interface HVACDiscountDetails {
  name: string;
  cost: number;
}

export interface HVACSystemDesign {
  accountId: ObjectId;
  isCurrent: boolean;
  equipment: HVACEquipmentDetails[];
  installKits?: HVACInstallKitDetails[];
  services?: HVACServiceDetails[];
  discounts?: HVACDiscountDetails[];
  ahriNumber?: string;
}

export type SystemDesignReturnType<T extends ProgramType> = T extends ProgramType.hvac
  ? HVACSystemDesign
  : SystemDesign;
