import { Link, useParams } from 'react-router-dom';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { useGetAccountQuery, useGetAccountCedDetailsQuery } from '../../services/accounts';
import { useGetCurrentDesignQuery } from '../../services/systemDesign';
import { PropertyListBox } from '../PropertyListBox/PropertyListBox';
// import { GlideECEligibility, energyCommunityMap } from 'types';
// import { FontColor, IconName } from '@palmetto/palmetto-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetAddersQuery } from '../../services/adders';
import { useGetQuotesQuery } from '../../services/quotes';
import { AdderType, QuoteStatus } from '../../types/Quotes';
import RequirePermissions from '../auth/requirePermissions';
import { Button, useOpenClose } from '@palmetto/palmetto-components';
import { AccountPaymentMethod } from './modals/AccountPaymentMethod';
import { GetSalesRepAuthLinkModal } from '../SalesRepVerification/GetSalesRepAuthLinkModal';
import { useGetPaymentMethodsByAccountIdQuery } from '@/services/paymentMethods';
import {
  AccountDocument,
  ExternalReferenceType,
  FinancialAccountPaymentMethodType,
  HVACDiscountDetails,
  HVACEquipmentDetails,
  HVACEquipmentTypeLabels,
  HVACInstallKitDetails,
  HVACServiceDetails,
  ProgramType,
  ProgramTypeLabels,
  UserPermissions,
} from 'types';
import { useAuth } from '../auth/authProvider';
import { convertIsoToDateMed } from '../Underwriting/CreditApplications';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { CedProgramNameLabels, CedProgramTypes } from 'types';
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber';
import usePermissions from '@/hooks/usePermissions';
import { DateTime } from 'luxon';
import { ReCheckSalesRepModal } from './modals/ReCheckSalesRepModal';
import { useGetSalesRepProviderUserBySalesRepIdQuery } from '@/services/salesReps';

const getSystemDesignLabel = (programType?: ProgramType) => {
  switch (programType) {
    case ProgramType.hvac:
      return 'System Design';
    case ProgramType.solar:
    case ProgramType.doePr:
    case ProgramType.newHomes:
      return 'PV System';
    default:
      return '';
  }
};

const getSystemDesignProperties = (account: AccountDocument<any>, systemDesign: any) => {
  switch (account?.programType) {
    case ProgramType.hvac:
      return [
        ...(systemDesign?.equipment?.map((e: HVACEquipmentDetails) => ({
          label: `${HVACEquipmentTypeLabels[e.type]} - ${e.name}`,
          value: e.quantity,
        })) || []),
        ...(systemDesign?.installKits?.map((i: HVACInstallKitDetails) => ({
          label: `Install Kit - ${i.name}`,
          value: i.quantity,
        })) || []),
        ...(systemDesign?.services?.map((s: HVACServiceDetails) => ({
          label: `Service - ${s.name}`,
          value: s.quantity,
        })) || []),
        ...(systemDesign?.discounts?.map((s: HVACDiscountDetails) => ({
          label: `Discount - ${s.name}`,
          value: 1,
        })) || []),
      ];
    case ProgramType.solar:
    case ProgramType.doePr:
    case ProgramType.newHomes:
      const solarAccount = account as AccountDocument<ProgramType.doePr | ProgramType.solar>;
      return [
        {
          label: 'System Size (kW)',
          value: systemDesign?.systemSizeKw ?? solarAccount?.systemDesign?.systemSizeKw ?? '--',
        },
        {
          label: 'Year-1 Production (kWh)',
          value:
            systemDesign?.systemFirstYearProductionKwh?.toFixed(0) ??
            solarAccount?.systemDesign?.systemFirstYearProductionKwh?.toFixed(0) ??
            '--',
        },
        {
          label: 'Panel Count',
          value:
            systemDesign?.totalPanelCount > 0
              ? systemDesign?.totalPanelCount
              : (solarAccount?.systemDesign?.totalPanelCount ?? '--'),
        },
        {
          label: 'Panel Manufacturer',
          value: systemDesign?.panelManufacturer ?? solarAccount?.systemDesign?.panelManufacturer ?? '--',
        },
        { label: 'Panel Model', value: systemDesign?.panelModel ?? solarAccount?.systemDesign?.panelModel ?? '--' },
        {
          label: 'Inverter Manufacturer',
          value:
            systemDesign?.inverters?.[0]?.manufacturer ?? solarAccount?.systemDesign?.inverterManufacturer ?? '--',
        },
        {
          label: 'Mounting Type',
          value: systemDesign?.mountingType ?? solarAccount?.systemDesign?.mountingType ?? '--',
        },
        {
          label: 'Hardware Manufacturer',
          value: systemDesign?.mountingManufacturer ?? solarAccount?.systemDesign?.mountingManufacturer ?? '--',
        },
      ];
    default:
      return [];
  }
};

const formatDateToUsaShortStandard = (dateString: string) => {
  const date = DateTime.fromFormat(dateString, 'dd/MM/yyyy');
  if (!date.isValid) {
    return dateString;
  }

  return date.toFormat('MM/dd/yyyy');
};

export function AccountDetails() {
  const { claims } = useAuth();
  const userPermissions = usePermissions();

  const { id } = useParams<{ id: any }>();
  const { data: account, isLoading }: any = useGetAccountQuery(id);
  const { data: paymentMethod = [] } = useGetPaymentMethodsByAccountIdQuery({ id });
  const { data: salesRepProviderUser, isLoading: isSalesRepInfoLoading } =
    useGetSalesRepProviderUserBySalesRepIdQuery(
      {
        salesRepId: account?.salesRepVerification?.salesRepReferenceId,
      },
      { skip: !account?.salesRepVerification?.salesRepReferenceId },
    );

  const { data: cedDetails }: any = useGetAccountCedDetailsQuery(id);
  const { data: systemDesign }: any = useGetCurrentDesignQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: quotes } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const activeQuote = quotes?.find((quote) => quote.status === QuoteStatus.active);
  const solarReadinessCost = activeQuote?.additionalCostAdders?.find(
    (adder: any) => adder.type === AdderType.solarReadiness,
  )?.cost;
  const { data: addersData } = useGetAddersQuery(id);
  const { isCosignerEnabled, salesRepVerification } = useFlags();
  const { isOpen: isAchModalOpen, handleOpen: openAchModal, handleClose: closeModal } = useOpenClose();
  const {
    isOpen: isReCheckModalOpen,
    handleOpen: openReCheckModal,
    handleClose: closeReCheckModal,
  } = useOpenClose();

  const programReferenceId = account?.externalReferenceIds?.find(
    (ref: any) => ref.type === ExternalReferenceType.doe,
  )?.id;
  const primaryAccountApplicant = account?.applicants.find((applicant: any) => applicant.type === 'primary');
  const secondaryAccountApplicant = account?.applicants.find((applicant: any) => applicant.type === 'secondary');
  const showAdditionalApplicant = isCosignerEnabled && secondaryAccountApplicant !== undefined;
  const {
    address1: primeAddress1,
    address2: primeAddress2 = '',
    city: primeCity,
    state: primeState,
    zip: primeZip,
  } = primaryAccountApplicant?.address || {};
  const { address1, address2 = '', city, state, zip } = account?.address || {};
  // const energyCommunity = account?.itcAdderQualifications?.ecEligibilityCriteria
  //   ? energyCommunityMap[account.itcAdderQualifications?.ecEligibilityCriteria as GlideECEligibility].map(
  //       (v: string) => v,
  //     )
  //   : false;

  const formattedPrimaryApplicantCreditDOB = primaryAccountApplicant?.creditDOB
    ? formatDateToUsaShortStandard(primaryAccountApplicant?.creditDOB)
    : '';

  const isLightReachAdmin = isPalmettoFinanceUser(claims) && userPermissions.includes(UserPermissions.admin);
  const accountDetailsProperties = {
    heading: 'Account Details',
    editPath: `/accounts/${id}/edit`,
    values: [
      { label: 'Account Id', value: id },
      { label: 'External Reference', value: account?.externalReference },
      { label: 'Program Type', value: ProgramTypeLabels[account?.programType as ProgramType] },
      { label: 'Program Reference ID', value: programReferenceId },
      ...(isLightReachAdmin
        ? [
            {
              label: 'Date of Birth (From Credit Report)',
              value: formattedPrimaryApplicantCreditDOB,
            },
          ]
        : []),
      { label: 'Language', value: account?.language },
      {
        label: 'Primary Contact',
        value: [
          `${primaryAccountApplicant?.firstName} ${primaryAccountApplicant?.lastName}`,
          primaryAccountApplicant?.email,
          primaryAccountApplicant?.phoneNumber ? formatPhoneNumber(primaryAccountApplicant.phoneNumber) : '',
        ],
      },
      ...(showAdditionalApplicant
        ? [
            {
              label: 'Additional Contact',
              value: [
                `${secondaryAccountApplicant?.firstName} ${secondaryAccountApplicant?.lastName}`,
                secondaryAccountApplicant?.email,
                secondaryAccountApplicant?.phoneNumber
                  ? formatPhoneNumber(secondaryAccountApplicant.phoneNumber)
                  : '',
              ],
            },
          ]
        : []),
      {
        label: 'Mailing Address',
        value: `${primeAddress1} ${primeAddress2} ${primeCity}, ${primeState} ${primeZip}`,
      },
      { label: 'Property Address', value: `${address1} ${address2} ${city}, ${state} ${zip}` },
      // {
      //   label: 'Energy Community',
      //   value: energyCommunity || 'No',
      //   icon: 'nature' as IconName,
      //   iconColor: energyCommunity ? ('primary' as FontColor) : ('grey-300' as FontColor),
      // },
      { label: 'Utility', value: account?.utility?.utilityName },
      { label: 'Status', value: account?.status },
    ],
  };

  const supportDetailsProperties = {
    heading: 'Account Support',
    values: [
      { label: 'Payment Provider', value: account?.inboundPayments ? account.inboundPayments.provider : 'Stripe' },
      {
        label: 'Provider ID',
        value: account?.inboundPayments ? (
          <Link to={account.inboundPayments.providerUrl}>{account.inboundPayments.providerId}</Link>
        ) : (
          account?.stripeCustomerId
        ),
      },
      {
        label: 'Payment Method',
        value:
          paymentMethod.length > 0 && paymentMethod[0].bankAccount ? (
            <Button variant="primary" tone="neutral" size="xs" onClick={() => openAchModal()}>
              {FinancialAccountPaymentMethodType.bankAccount}
            </Button>
          ) : paymentMethod.length > 0 && paymentMethod[0].card ? (
            <Button variant="primary" tone="neutral" size="xs" onClick={() => {}}>
              {FinancialAccountPaymentMethodType.card}
            </Button>
          ) : (
            <Button variant="primary" size="xs" onClick={() => openAchModal()}>
              Add
            </Button>
          ),
      },
    ],
  };

  const pvSystemProperties = {
    heading: getSystemDesignLabel(account?.programType),
    editPath: `/accounts/${id}/system-design`,
    values: getSystemDesignProperties(account, systemDesign),
    shouldFold: true,
  };
  const addersMap = activeQuote?.adders?.reduce((acc: any, type: string) => {
    const adder = addersData?.adders?.find((adder: any) => adder.type === type);

    if (adder) {
      acc[type] = adder;
    } else {
      acc[type] = {};
    }

    return acc;
  }, {});
  const addersProperties = {
    heading: 'Adders',
    editPath: `/accounts/${id}/adders`,
    values: [
      ...(addersMap?.arbitrageBattery
        ? [
            {
              label: 'Arbitrage Battery',
              value: [
                `${addersMap?.arbitrageBattery?.manufacturer ?? ''} ${addersMap?.arbitrageBattery?.model ?? ''}`,
                addersMap?.arbitrageBattery?.quantity ? `x${addersMap.arbitrageBattery.quantity}` : 'Included',
              ],
            },
          ]
        : []),
      ...(addersMap?.backupBattery
        ? [
            {
              label: 'Backup Battery',
              value: [
                `${addersMap?.backupBattery?.manufacturer ?? ''} ${addersMap?.backupBattery?.model ?? ''}`,
                addersMap?.backupBattery?.quantity ? `x${addersMap.backupBattery.quantity}` : 'Included',
              ],
            },
          ]
        : []),
      ...(addersMap?.electricalUpgrade ? [{ label: 'Electrical Upgrade', value: 'Included' }] : []),
      ...(activeQuote?.adders?.includes(AdderType.solarReadiness)
        ? [{ label: 'Solar Readiness', value: currencyFormatter.format(solarReadinessCost ?? 0) }]
        : []),
      ...(!activeQuote?.adders?.length ? [{ label: 'None', value: '' }] : []),
    ],
  };

  const salesRepProperties = {
    heading: 'Sales Rep',
    editPath: `/accounts/${id}/edit`,
    values: [
      { label: 'Name', value: account?.salesRepName },
      { label: 'License', value: account?.salesRepLicenseNumber },
      { label: 'Email', value: account?.salesRepEmail },
      { label: 'Phone', value: account?.salesRepPhoneNumber },
      ...(salesRepVerification
        ? [
            {
              label: 'ReCheck Status',
              value: account?.salesRepVerification?.status ? (
                <Button variant="primary" tone="neutral" size="xs" onClick={() => openReCheckModal()}>
                  {account?.salesRepVerification?.status?.toUpperCase()}
                </Button>
              ) : (
                <GetSalesRepAuthLinkModal />
              ),
            },
          ]
        : []),
    ],
  };

  const partnerOrgProperties = {
    heading: 'Partner Org',
    values: [
      { label: 'Name', value: account?.organizationId?.toUpperCase() },
      { label: 'Licensed Partner Org', value: account?.licensedOrganizationId?.toUpperCase() || '' },
    ],
  };

  const cedSplitPayProperties = {
    heading: 'Greentech Direct Pay',
    values: [
      { label: 'Greentech Direct Pay Enabled', value: cedDetails?.cedSplitPayEnabled ? 'Yes' : 'No' },
      { label: 'Greentech Project Id', value: cedDetails?.cedProjectId || '' },
      ...(isPalmettoFinanceUser(claims)
        ? [
            {
              label: 'Greentech Program Name',
              value: cedDetails?.programName ? CedProgramNameLabels[cedDetails?.programName as CedProgramTypes] : '',
            },
            {
              label: 'Parallel Payment Amount',
              value: currencyFormatter.format(cedDetails?.parallelPaymentAmount ?? 0),
            },
            { label: 'Parallel Payment Status', value: cedDetails?.parallelPaymentStatus || '' },
            {
              label: 'Ship Confirmation Date',
              value: cedDetails?.shipConfirmationDate
                ? convertIsoToDateMed(cedDetails?.shipConfirmationDate, { zone: 'UTC' })
                : '',
            },
          ]
        : []),
    ],
  };

  const pricingDetailsBoxProperties =
    account?.priceSheetMappingId && isLightReachAdmin
      ? {
          heading: 'Pricing Details',
          values: [
            {
              label: 'Price Mappings',
              value: (
                <Link
                  to={`${location.origin}/settings/organizations/${account.licensedOrganizationId}/pricing/mappings?id=${account?.priceSheetMappingId}`}
                >
                  Account Pricing
                </Link>
              ),
            },
          ],
        }
      : null;

  return (
    <>
      {!isLoading && account && cedDetails && (
        <>
          <PropertyListBox {...accountDetailsProperties} />
          <RequirePermissions permissions={['lightReachSupport']}>
            <AccountPaymentMethod
              isModalOpen={isAchModalOpen}
              closeModal={closeModal}
              paymentMethods={paymentMethod}
            />
            <PropertyListBox {...supportDetailsProperties} />
          </RequirePermissions>
          <PropertyListBox {...pvSystemProperties} />
          {account.programType !== ProgramType.hvac && <PropertyListBox {...addersProperties} />}
          <PropertyListBox {...salesRepProperties} />
          {salesRepVerification && !isSalesRepInfoLoading && salesRepProviderUser && (
            <ReCheckSalesRepModal
              isModalOpen={isReCheckModalOpen}
              closeModal={closeReCheckModal}
              salesRepAccountData={salesRepProviderUser}
              salesRepReCheckStatus={account?.salesRepVerification?.status}
            />
          )}
          <PropertyListBox {...partnerOrgProperties} />
          {pricingDetailsBoxProperties && <PropertyListBox {...pricingDetailsBoxProperties} />}
          {account?.ced?.cedSplitPayEnabled && <PropertyListBox {...cedSplitPayProperties} />}
        </>
      )}
    </>
  );
}
