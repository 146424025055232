import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { DocumentList } from './Documents/DocumentList';
import { InstallDocumentTypes } from 'types';
import { PermitDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';

export const Permit = ({ allowArchive }: { allowArchive?: boolean }) => {
  const { isSubmitting } = useFormikContext<any>();

  return (
    <FormSection title="Permit" description={PermitDescription}>
      <DocumentList
        documentTypeFilter={[InstallDocumentTypes.permit]}
        title="Approved Permit"
        showTypeLabelOnItems={false}
        baseCategory={'Permit'}
        allowArchive={allowArchive}
      />
      <Box childGap="lg">
        <Field
          id="permitSubmittedDate"
          component={FormikTextInput}
          autoComplete="off"
          label="Permit Application Submitted"
          name="permitSubmittedDate"
          inputMask="date"
          type="tel"
          placeholder="MM-DD-YYYY"
          isDisabled={isSubmitting}
          helpText="When the application was submitted to the local authority"
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id="permitApprovedDate"
          component={FormikTextInput}
          autoComplete="off"
          label="Permit Application Approval"
          name="permitApprovedDate"
          inputMask="date"
          type="tel"
          placeholder="MM-DD-YYYY"
          isDisabled={isSubmitting}
          helpText="When the permit was approved by the local authority"
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id="installScheduledDate"
          component={FormikTextInput}
          autoComplete="off"
          label="Install Date"
          name="installScheduledDate"
          inputMask="date"
          type="tel"
          placeholder="MM-DD-YYYY"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
        <Field
          id="expectedPTODate"
          component={FormikTextInput}
          autoComplete="off"
          label="Expected PTO Date"
          name="expectedPTODate"
          inputMask="date"
          type="tel"
          placeholder="MM-DD-YYYY"
          isDisabled={isSubmitting}
          width={{ base: '3xl', tablet: '4xl' }}
        />
      </Box>
    </FormSection>
  );
};
