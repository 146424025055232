import { Box, toast } from '@palmetto/palmetto-components';
import { useEffect, useMemo, useState } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { isObject, isString } from 'lodash';
import { Formik, FormikProps } from 'formik';
import { MainContainer } from '../MainContainer';
import FormSectionContainer from '../Forms/FormSectionContainer';
import { useLazyGetHVACPricingQuery } from '../../services/pricing';
import { useCreateQuoteMutation } from '../../services/quotes';
import {
  EscalatorRateSection,
  FormActionsSection,
  FormResultSection,
  IDataResponse,
  ISelectionOption,
  TermFormSection,
  TotalFinancedAmountFormSection,
} from './HVACQuoteCalculator.components';
import {
  calculateFilteredValuesToDisplay,
  calculateInitialFormValues,
  calculateLoadedHVACPricingData,
  calculateSelectedFinancingOption,
  handleSearchRequest,
  handleSubmitRequest,
} from './HVACQuoteCalculator.utils';
import LoadingOverlay from '../LoadingOverlay';

function containsISelectionOption(this: yup.MixedSchema, message: string, elementsToCompare: ISelectionOption[]) {
  return this.test('containsISelectionOption', message, function (value) {
    const { path, createError } = this;
    const isComparisonArrayNotProvided = !Array.isArray(elementsToCompare);
    const valueNotProvided = typeof value === 'undefined';

    if (isComparisonArrayNotProvided) {
      return true;
    }

    if (valueNotProvided) {
      return true;
    }

    const found = elementsToCompare?.find((element) => {
      if (isObject(value)) {
        return (value as ISelectionOption).id === element.id;
      }
      if (isString(value)) {
        return (value as string) === element.value;
      }
      return false;
    });

    if (!found) {
      return createError({ path, message: message ?? 'The term is not valid' });
    }

    return true;
  });
}

//Added due conflicting checking when switching to different values programatically (form values dynamically set)
yup.addMethod(yup.mixed, 'containsISelectionOption', containsISelectionOption);

const QuoteCalculator = ({ account }: { account: any }) => {
  const [totalFinancedAmount, setTotalFinancedAmount] = useState<number>(0);
  const [selectedTermOption, setSelectedTermOption] = useState<ISelectionOption>();
  const [selectedRateOption, setSelectedRateOption] = useState<ISelectionOption>();
  const [loadingHVACPricingData, setLoadingHVACPricingData] = useState<boolean>(false);
  const [creatingQuote, setCreatingQuote] = useState<boolean>(false);
  const [initialSearchAt, setInitialSearchAt] = useState<number>(0);
  const navigate = useNavigate();

  const [triggerSearch, { data: _data = [] }] = useLazyGetHVACPricingQuery();

  const [loadedHVACPricingData, setLoadedHVACPricingData] = useState<IDataResponse[]>([]);

  const [createQuote] = useCreateQuoteMutation();

  async function handleSubmit() {
    try {
      await handleSubmitRequest({
        totalFinancedAmount,
        chosenElement,
        setCreatingQuote,
        account,
        createQuote,
      });
      navigate(`/accounts/${account.id}`);
    } catch (e) {}
  }

  async function handleTotalFinancedAmountOnChange(
    numericValue: number,
    props: FormikProps<{
      term: ISelectionOption | undefined;
      rate: string | undefined;
      totalFinancedAmount: number;
    }>,
  ) {
    props.setFieldValue('totalFinancedAmount', numericValue);
    setTotalFinancedAmount(numericValue);
  }

  //We debounce search
  useEffect(() => {
    let effectTimeout: any = 0;
    let loadingToastId: string = '';
    const timeoutId = setTimeout(() => {
      if (totalFinancedAmount && totalFinancedAmount > 0) {
        setLoadingHVACPricingData(true);
        if (!loadingHVACPricingData) {
          loadingToastId = toast.loading('Loading information...');
          handleSearchRequest({
            totalFinancedAmount,
            setLoadingHVACPricingData,
            setLoadedHVACPricingData,
            triggerSearch,
            accountId: account?.id,
            initialSearchAt,
            setInitialSearchAt,
            loadingToastId,
          }).then((timeoutRef) => {
            effectTimeout = timeoutRef;
          });
        }
      }
    }, 500);
    return () => {
      toast.dismiss(loadingToastId);
      clearTimeout(timeoutId);
      clearTimeout(effectTimeout);
      setLoadingHVACPricingData(false);
    };
  }, [totalFinancedAmount]);

  const { rateOptions, termOptions, mappedTerms, mappedRates } = useMemo(
    () =>
      calculateLoadedHVACPricingData({
        loadedHVACPricingData,
      }),
    [loadedHVACPricingData],
  );

  const filteredValuesToDisplay = useMemo(
    () =>
      calculateFilteredValuesToDisplay({
        selectedRateOption,
        selectedTermOption,
        termOptions,
        rateOptions,
        mappedTerms,
        mappedRates,
      }),
    [selectedRateOption, selectedTermOption, termOptions, rateOptions],
  );

  const initialFormValues = useMemo(() => {
    const formValues = calculateInitialFormValues({
      filteredValuesToDisplay,
      selectedRateOption,
      selectedTermOption,
      totalFinancedAmount,
    });
    return formValues;
  }, [filteredValuesToDisplay, selectedTermOption, selectedRateOption]);

  const chosenElement = useMemo(
    () =>
      calculateSelectedFinancingOption({
        loadedHVACPricingData,
        selectedTermOption: termOptions.find((element) => {
          return element.value === initialFormValues.term;
        }),
        selectedRateOption: rateOptions.find((element) => {
          return element.value === initialFormValues.rate;
        }),
      }),
    [loadedHVACPricingData, initialFormValues],
  );

  const calculatorValidationSchema = yup.object().shape({
    totalFinancedAmount: yup.string().required(),
    term: (yup.mixed() as any).containsISelectionOption('No valid option provided', filteredValuesToDisplay.terms),
    rate: (yup.mixed() as any).containsISelectionOption('No valid option provided', filteredValuesToDisplay.rates),
  });

  //Whether to show additional inputs or not.
  const showAdditionalInputs = !!initialSearchAt;

  return (
    <MainContainer>
      <Box as="h2" fontWeight="medium" fontSize="lg" padding={{ base: 'lg lg 0 lg' }}>
        Quote Calculator
        <Box padding={{ base: 'xs 0 0 0' }}>
          <Formik
            initialValues={initialFormValues as any /**TODO */}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={calculatorValidationSchema}
            validateOnChange={true}
          >
            {(props) => (
              <Form style={{ width: '100%' }} onSubmit={props.handleSubmit}>
                <Box position="relative">
                  {loadingHVACPricingData && <LoadingOverlay isVisible={true} />}
                  <FormSectionContainer>
                    <TotalFinancedAmountFormSection
                      value={totalFinancedAmount}
                      onChange={(numericValue) => handleTotalFinancedAmountOnChange(numericValue, props)}
                      disabled={loadingHVACPricingData || creatingQuote}
                    />
                    {showAdditionalInputs && (
                      <>
                        {_data.length > 0 || loadingHVACPricingData ? (
                          <>
                            <TermFormSection
                              terms={filteredValuesToDisplay.terms}
                              isDisabled={
                                filteredValuesToDisplay.terms.length <= 0 || loadingHVACPricingData || creatingQuote
                              }
                              onChange={(selectedOption: ISelectionOption) => {
                                props.setFieldValue('term', selectedOption.value);
                                setSelectedTermOption(selectedOption);
                              }}
                            />
                            <EscalatorRateSection
                              rates={filteredValuesToDisplay.rates}
                              disabled={
                                filteredValuesToDisplay.rates.length < 1 || loadingHVACPricingData || creatingQuote
                              }
                              onChange={(selectedValue) => {
                                props.setFieldValue('term', selectedValue);
                                const filteredValue = filteredValuesToDisplay.rates.find(
                                  (element) => element.value === selectedValue,
                                );
                                setSelectedRateOption(filteredValue);
                              }}
                            />
                            {chosenElement ? <FormResultSection data={chosenElement} /> : null}
                          </>
                        ) : (
                          !loadingHVACPricingData && (
                            <Box alignItems="center" justifyContent="center">
                              Couldn't find matching products. Please try with another amount.
                            </Box>
                          )
                        )}
                      </>
                    )}
                  </FormSectionContainer>
                </Box>

                <FormActionsSection
                  isLoading={loadingHVACPricingData || creatingQuote}
                  selectedElement={chosenElement}
                  onCancel={() => {
                    navigate(`/accounts/${account.id}`);
                  }}
                />
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default QuoteCalculator;
