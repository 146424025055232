import { IDataResponse, ISelectionOption } from './HVACQuoteCalculator.components';
import { toast } from '@palmetto/palmetto-components';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/query';

export async function handleSubmitRequest({
  totalFinancedAmount,
  chosenElement,
  setCreatingQuote,
  account,
  createQuote,
}: {
  totalFinancedAmount: number;
  chosenElement: IDataResponse | undefined;
  setCreatingQuote: React.Dispatch<React.SetStateAction<boolean>>;
  account: any;
  createQuote: (arg: { quoteData: any; id: string; programType?: any }) => MutationActionCreatorResult<any>;
}) {
  if (chosenElement) {
    setCreatingQuote(true);
    const loadingToastId = toast.loading('Creating quote...');
    try {
      await handleCreationRequest({
        chosenElement,
        totalFinancedAmount,
        account,
        createQuote,
      });
      setCreatingQuote(false);
      toast.dismiss(loadingToastId);
      toast.success('Successfully created quote.');
    } catch (e) {
      setCreatingQuote(false);
      let errorMessage: string = (e as any)?.data?.message || 'Something wrong happened. Please try again.';
      toast.error(errorMessage);
      toast.dismiss(loadingToastId);
      throw e;
    }
  }
}

export async function handleSearchRequest({
  totalFinancedAmount,
  setLoadingHVACPricingData,
  setLoadedHVACPricingData,
  triggerSearch,
  accountId,
  initialSearchAt,
  setInitialSearchAt,
  loadingToastId,
  leastTimeWaitedAfterResultsGotten = 800,
}: {
  totalFinancedAmount: number;
  setLoadingHVACPricingData: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadedHVACPricingData: React.Dispatch<React.SetStateAction<IDataResponse[]>>;
  triggerSearch: any;
  accountId: string;
  initialSearchAt?: number;
  setInitialSearchAt: (value: React.SetStateAction<number>) => void;
  loadingToastId?: string;
  leastTimeWaitedAfterResultsGotten?: number;
}) {
  if (isNaN(totalFinancedAmount)) {
    return;
  }
  setLoadingHVACPricingData(true);
  let timeoutRef: any = 0;
  try {
    const { data } = await triggerSearch({
      accountId: accountId,
      totalFinancedAmount: totalFinancedAmount,
    });
    timeoutRef = setTimeout(() => {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
      const informationToShow = data || [];
      setLoadedHVACPricingData(informationToShow);
      setLoadingHVACPricingData(false);
      if (!initialSearchAt && informationToShow?.length) {
        setInitialSearchAt(Date.now());
      }
      if (!informationToShow?.length) {
        toast.error("Couldn't find matching products. Please try with another amount.");
      }
    }, leastTimeWaitedAfterResultsGotten);
  } catch (e) {
    timeoutRef = setTimeout(() => {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
      setLoadingHVACPricingData(false);
      if (!initialSearchAt) {
        setInitialSearchAt(Date.now());
      }
      toast.error('Something wrong happened loading the data. Please try again.');
    }, leastTimeWaitedAfterResultsGotten);
  }
  return timeoutRef;
}

/**
 * @description Gets the selected financing option based off the selected
 * term and rate options.
 */
export function calculateSelectedFinancingOption({
  loadedHVACPricingData,
  selectedTermOption,
  selectedRateOption,
}: {
  loadedHVACPricingData: IDataResponse[];
  selectedTermOption?: ISelectionOption;
  selectedRateOption?: ISelectionOption;
}) {
  return loadedHVACPricingData.find((element) => {
    return (
      `${element.term}` === selectedTermOption?.value && `${element.escalationRate}` === selectedRateOption?.value
    );
  });
}

/**
 * @description Calculates the initial form values that will be assigned to the form.
 */
export function calculateInitialFormValues({
  filteredValuesToDisplay,
  selectedRateOption,
  selectedTermOption,
  totalFinancedAmount,
}: {
  filteredValuesToDisplay: {
    terms: ISelectionOption[];
    rates: ISelectionOption[];
  };
  selectedRateOption?: ISelectionOption;
  selectedTermOption?: ISelectionOption;
  totalFinancedAmount?: number;
}) {
  const filteredSelectedTerm = filteredValuesToDisplay.terms?.find((element) => {
    return element.id === selectedTermOption?.id;
  });
  const filteredSelectedRate = filteredValuesToDisplay.rates?.find((element) => {
    return element.id === selectedRateOption?.id;
  });
  let term: ISelectionOption | undefined;
  if (filteredSelectedTerm) {
    term = filteredSelectedTerm;
  } else {
    if (filteredValuesToDisplay.terms.length == 1) {
      term = filteredValuesToDisplay.terms[0];
    }
  }

  let rate: ISelectionOption | undefined = undefined;
  if (filteredSelectedRate) {
    rate = filteredSelectedRate;
  } else {
    if (filteredValuesToDisplay.rates.length == 1) {
      rate = filteredValuesToDisplay.rates[0];
    }
  }

  return {
    term: term?.value,
    rate: rate?.value,
    totalFinancedAmount: totalFinancedAmount || 0,
  };
}

/**
 * Gets the display values based off custom rules.
 */
export function calculateFilteredValuesToDisplay({
  selectedRateOption,
  selectedTermOption,
  termOptions,
  rateOptions,
  mappedTerms,
  mappedRates,
}: {
  selectedRateOption: ISelectionOption | undefined;
  selectedTermOption: ISelectionOption | undefined;
  termOptions: ISelectionOption[];
  rateOptions: ISelectionOption[];
  mappedTerms: {
    [index: string]: IDataResponse[];
  };
  mappedRates: {
    [index: string]: IDataResponse[];
  };
}) {
  let filteredTermOptions = termOptions.filter((element) => {
    if (!selectedRateOption) {
      return true;
    }

    const foundTerm = mappedTerms[element.value].find((mappedElement) => {
      return `${mappedElement.escalationRate}` === selectedRateOption?.value;
    });

    return foundTerm;
  });

  //We avoid leaving only a single or no selectable term.
  if (filteredTermOptions.length <= 1) {
    filteredTermOptions = termOptions;
  }

  const filteredRateOptions = rateOptions.filter((element) => {
    if (!selectedTermOption) {
      return true;
    }

    const foundRate = mappedRates[element.value].find((mappedElement) => {
      return `${mappedElement.term}` === selectedTermOption?.value;
    });
    return !!foundRate;
  });

  return {
    terms: filteredTermOptions,
    rates: filteredRateOptions,
  };
}

/**
 * @description Formats the incoming data used for display purposes.
 * @param param0 The pricing data
 * @returns The parsed pricing data for display and further calculation
 */
export function calculateLoadedHVACPricingData({
  loadedHVACPricingData,
}: {
  loadedHVACPricingData: IDataResponse[];
}) {
  const mapHVACPricingDataToValues = function () {
    const mappedRateValues: {
      [index: string]: IDataResponse[];
    } = {};
    const mappedTermValues: {
      [index: string]: IDataResponse[];
    } = {};
    for (let i = 0; i < loadedHVACPricingData?.length; i++) {
      if (Array.isArray(mappedRateValues[loadedHVACPricingData[i].escalationRate])) {
        mappedRateValues[loadedHVACPricingData[i].escalationRate.toString()].push(loadedHVACPricingData[i]);
      } else {
        mappedRateValues[loadedHVACPricingData[i].escalationRate.toString()] = [loadedHVACPricingData[i]];
      }
      if (Array.isArray(mappedTermValues[loadedHVACPricingData[i].term])) {
        mappedTermValues[loadedHVACPricingData[i].term.toString()].push(loadedHVACPricingData[i]);
      } else {
        mappedTermValues[loadedHVACPricingData[i].term.toString()] = [loadedHVACPricingData[i]];
      }
    }
    return {
      mappedTermValues,
      mappedRateValues,
    };
  };
  const { mappedRateValues, mappedTermValues } = mapHVACPricingDataToValues();

  const sortEscalatorRateKeysByValue = function (rate1Key: string, rate2Key: string) {
    if (mappedRateValues[rate1Key][0].escalationRate > mappedRateValues[rate2Key][0].escalationRate) {
      return 1;
    }
    if (mappedRateValues[rate1Key][0].escalationRate < mappedRateValues[rate2Key][0].escalationRate) {
      return -1;
    }
    return 0;
  };

  const sortTermKeysByValue = function (term1Key: string, term2Key: string) {
    if (mappedTermValues[term1Key][0].term > mappedTermValues[term2Key][0].term) {
      return 1;
    }
    if (mappedTermValues[term1Key][0].term < mappedTermValues[term2Key][0].term) {
      return -1;
    }
    return 0;
  };

  const rateOptionsCalculated: ISelectionOption[] = Object.keys(mappedRateValues)
    .sort(sortEscalatorRateKeysByValue)
    .map((escalatorKey) => ({
      label: `${Number(mappedRateValues[escalatorKey][0].escalationRate * 100).toFixed(2)}%`,
      value: `${mappedRateValues[escalatorKey][0].escalationRate}`,
      id: `${mappedRateValues[escalatorKey][0].escalationRate}`,
    }));

  const termOptionsCalculated: ISelectionOption[] = Object.keys(mappedTermValues)
    .sort(sortTermKeysByValue)
    .map((element) => ({
      label: `${mappedTermValues[element][0].term} Years`,
      value: `${mappedTermValues[element][0].term}`,
      id: `${mappedTermValues[element][0].term}`,
    }));

  return {
    rateOptions: rateOptionsCalculated,
    termOptions: termOptionsCalculated,
    mappedTerms: mappedTermValues,
    mappedRates: mappedRateValues,
  };
}

/**
 * @description Handles the quote creation request.
 * @param param0 The required parameters
 */
export async function handleCreationRequest({
  chosenElement,
  totalFinancedAmount,
  account,
  createQuote,
}: {
  chosenElement?: IDataResponse;
  totalFinancedAmount: number;
  account: any;
  createQuote: (arg: { quoteData: any; id: string; programType?: string }) => MutationActionCreatorResult<any>;
}) {
  if (!chosenElement && !totalFinancedAmount) {
    return;
  }

  const creationPayload = {
    quoteData: {
      productId: chosenElement?.productId,
      externalReference: account?.externalReference || undefined,
      totalFinancedAmount: totalFinancedAmount,
    },
    id: account.id,
    programType: 'hvac',
  } as any;

  await createQuote(creationPayload).unwrap();
}
