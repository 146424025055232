import { Box, Button, Icon } from '@palmetto/palmetto-components';
import { CompleteQueueReview } from '../Queues/CompleteQueueReview/CompleteQueueReview';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import { useContext } from 'react';
import { QueueFlagsContext } from '../QueueFlags/QueueFlagsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { QueueType } from 'types';

interface FooterProps {
  isFirstSubmission: boolean;
  isLoading: boolean;
  canSubmitPackage: boolean;
  canSubmitReview: boolean;
  disableApproval?: boolean;
  disableAllApprovals?: boolean;
  queueType: QueueType;
  disabled?: boolean;
  onSave: ({ accountId, data }: { accountId: string; data: FormikValues }) => Promise<void>;
}
export const Footer = ({
  isFirstSubmission,
  isLoading,
  canSubmitPackage,
  canSubmitReview,
  disableApproval,
  disableAllApprovals,
  onSave,
  queueType,
  disabled,
}: FooterProps) => {
  const navigate = useNavigate();
  const { id = '', action = 'review' } = useParams<{ id: string; action: ReviewPackageActions }>();
  const { isSubmitting, errors, values, setSubmitting, touched } = useFormikContext<any>();

  const { getAllFlags } = useContext(QueueFlagsContext);
  const flags = getAllFlags ? getAllFlags(false) : [];
  const flagCount = flags.length;

  const handleSave = async () => {
    setSubmitting(true);
    await onSave({ accountId: id, data: values });
    setSubmitting(false);
  };

  const formErrors = Object.keys(errors).reduce((acc: FormikErrors<any>, key: string) => {
    const errorVal = touched[key] ? { [key]: errors[key] } : {};
    return {
      ...acc,
      ...errorVal,
    };
  }, {});

  return (
    <Box direction="column" gap="2xs">
      {Object.keys(formErrors).length ? (
        <Box color="danger" fontSize="sm" padding="sm" style={{ marginLeft: 'auto' }}>
          This form contains errors. Please resolve before submitting.
        </Box>
      ) : null}
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        alignItems={{
          base: 'stretch',
          tablet: 'flex-end',
        }}
        justifyContent={{
          tablet: 'flex-end',
        }}
        childGap="sm"
        style={{ flexShrink: 0 }}
        padding={{ desktop: 'lg 0 0 0', base: 'lg lg' }}
      >
        {flagCount > 0 && (
          <Box direction="row" fontSize="sm" padding="sm" gap="2xs" radius="sm" alignItems="center">
            <Icon name="flag" color="danger" />
            <Box color="danger">{`${flagCount} ${flagCount > 1 ? 'items flagged' : 'item flagged'}`}</Box>
          </Box>
        )}
        <Button
          as="a"
          navigate={() => navigate(`/accounts/${id ?? ''}`)}
          variant="secondary"
          tone="neutral"
          size="md"
          isLoading={isSubmitting || isLoading}
        >
          Cancel
        </Button>
        <Button
          size="md"
          variant="secondary"
          tone="primary"
          isLoading={isSubmitting || isLoading}
          onClick={handleSave}
          disabled={disabled}
        >
          Save
        </Button>
        {action === ReviewPackageActions.review ? (
          <CompleteQueueReview
            queueType={queueType}
            canSubmitReview={canSubmitReview}
            disableApproval={disableApproval}
            disableAllApprovals={disableAllApprovals}
            handleFormSave={handleSave}
          />
        ) : (
          <Button
            size="md"
            variant="primary"
            type="submit"
            isLoading={isSubmitting || isLoading}
            disabled={!canSubmitPackage || !!Object.keys(formErrors).length || disabled}
          >
            {isFirstSubmission ? 'Submit' : 'Resubmit'} for Review
          </Button>
        )}
      </Box>
    </Box>
  );
};
