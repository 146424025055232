import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { MainContainer } from '../MainContainer';
import { Badge, Box, Button, Card, Pagination, Table, toast, useOpenClose } from '@palmetto/palmetto-components';
import RequirePermissions from '../auth/requirePermissions';
import {
  useGetSalesRepListFiltersQuery,
  useLazyGetSalesRepsQuery,
  useUpdateSalesRepMutation,
} from '@/services/salesReps';
import { AppliedDataFilter, SalesRepStatus } from 'types';
import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { SalesRepActionModal } from './SalesRepActionModal';
import { SkeletonBox } from '../SkeletonBox';
import { FilterBar } from '../filters';
import { AccountsListLoading } from '../AccountsListLoading';
import { AddFilterButtonDropdown } from '../filters/AddFilterButton';

export const SalesReps = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [trigger, { data: searchResponse, isLoading, isFetching }] = useLazyGetSalesRepsQuery();
  const [updateSalesRep, { isLoading: isUpdatingSalesRep }] = useUpdateSalesRepMutation();
  const [salesRep, setSalesRep] = useState<any>();
  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([]);
  const { salesReps, total } = searchResponse || { salesReps: [], total: 0 };
  const { data = [], isLoading: isFiltersLoading } = useGetSalesRepListFiltersQuery();

  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const currentPage = searchParams.get('page');
  const advancedFilters = searchParams.get('advancedFilters');
  const searchTerm = searchParams.get('search') || undefined;

  useEffect(() => {
    const pageNum = currentPage ? Number(currentPage) : 1;
    const filters = advancedFilters || JSON.stringify([]);

    setAppliedFilters(JSON.parse(filters));
    trigger({
      pageNum,
      advancedFilters: filters,
      searchTerm,
    });
  }, [trigger, currentPage, advancedFilters, searchTerm]);

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      searchParams.delete('page');
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      searchParams.delete('page');
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  const onPageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const onSearchChange = (searchTerm: string) => {
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const handleOpenModal = (newStatus: string, salesRep: any) => {
    const actionToPerform = newStatus === SalesRepStatus.active ? 'unblock' : 'block';
    setSalesRep({ ...salesRep, actionToPerform, newStatus });
    openModal();
  };
  const handleCloseModal = () => {
    setSalesRep(undefined);
    closeModal();
  };

  const handleUpdateStatus = async () => {
    const toastId = toast.loading(
      `${salesRep.actionToPerform.charAt(0).toUpperCase() + salesRep.actionToPerform.slice(1)}ing sales rep...`,
    );

    try {
      await updateSalesRep({ id: salesRep.id, status: salesRep.newStatus });
    } catch (e: any) {
      console.error(e);
      toast.error(e?.data?.message || `An error occurred while ${salesRep.actionToPerform}ing the sales rep.`);
    } finally {
      toast.dismiss(toastId);
      handleCloseModal();
    }
  };

  const columns = [
    {
      heading: 'Status',
      dataKey: 'status',
      width: 125,
      render: (cell: any) => (
        <Badge variant={cell === 'active' ? 'success' : cell === 'blocked' ? 'danger' : 'warning'} message={cell} />
      ),
    },
    {
      heading: 'ReCheck Id',
      width: 200,
      render: (_cell: any, row: any) => <Link to={`${row.id}`}>{row.idProviderUser?.idProviderId}</Link>,
    },
    {
      heading: 'Sales Rep',
      render: (_cell: any, row: any) => (
        <Box display="block" childGap="2xs" fontSize="xs" padding="sm 0" color="body-secondary">
          <Box display="block" fontWeight="medium" fontSize="sm">
            {row.idProviderUser?.displayName}
          </Box>
          <Box>{row.idProviderUser?.emails[0]}</Box>
        </Box>
      ),
    },
    {
      heading: 'Total # Emails (LightReach)',
      width: 120,
      headerClassName: 'wrap',
      render: (_cell: any, row: any) => row.accountEmails?.length,
    },
    {
      heading: 'Total # Emails (ReCheck)',
      width: 120,
      headerClassName: 'wrap',
      render: (_cell: any, row: any) => row.idProviderUser?.emails?.length,
    },
    {
      heading: 'Total # Orgs (LightReach)',
      width: 120,
      headerClassName: 'wrap',
      render: (_cell: any, row: any) => row.organizationIds?.length,
    },
    {
      heading: 'Actions',
      width: 80,
      render: (_cell: any, row: any) => (
        <Box childGap="md">
          {row.status !== 'blocked' ? (
            <RequirePermissions permissions={['reCheckAdmin']}>
              <Button
                iconPrefix="ban"
                size="sm"
                variant="secondary"
                tone="danger"
                aria-label="Block Sales Rep"
                onClick={() => handleOpenModal(SalesRepStatus.blocked, row)}
              />
            </RequirePermissions>
          ) : null}
          {row.status !== 'active' ? (
            <RequirePermissions permissions={['reCheckAdmin']}>
              <Button
                iconPrefix="checkbox-btn-checked"
                size="sm"
                aria-label="Activate Sales Rep"
                variant="secondary"
                onClick={() => handleOpenModal(SalesRepStatus.active, row)}
              />
            </RequirePermissions>
          ) : null}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Manage Sales Reps</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Manage Sales Reps"></PageHeader>
        <Card padding="sm">
          {isLoading ? (
            <Box height="52px" alignItems="center" direction="row" childGap="xl">
              <SkeletonBox width="80px" height="14px" />
              <SkeletonBox width="170px" height="14px" />
              <SkeletonBox width="80px" height="14px" />
              <SkeletonBox width="100px" height="14px" />
            </Box>
          ) : (
            <>
              <Box margin="0 lg 0 lg">
                <FilterBar
                  filtersState={{
                    searchTerm,
                  }}
                  handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
                  placeholder="Search name, email, ReCheck Id..."
                >
                  {!isFiltersLoading && (
                    <AddFilterButtonDropdown
                      dataFilters={data}
                      appliedFilters={appliedFilters}
                      onRemoveFilter={handleRemoveAdvancedFilter}
                      onApplyFilters={handleApplyAdvancedFilters}
                      onClearFilters={handleClearAdvancedFilters}
                    />
                  )}
                </FilterBar>
                {isFetching || isLoading ? (
                  <AccountsListLoading rows={12} />
                ) : (
                  <Table rowKey="id" columns={columns} rows={salesReps} isScrollable={{ x: true }} />
                )}
              </Box>
              {salesReps?.length > 0 && (
                <Pagination
                  activePage={Number(currentPage) || 1}
                  arePagesVisible
                  itemsPerPage={20}
                  numberOfPagesDisplayed={3}
                  onChange={(page) => onPageChange(page)}
                  totalItemsCount={total ?? 0}
                  isCompact
                />
              )}
            </>
          )}
        </Card>
      </MainContainer>
      <SalesRepActionModal
        isModalOpen={isModalOpen}
        salesRep={salesRep}
        handleCloseModal={handleCloseModal}
        handleUpdateStatus={handleUpdateStatus}
        isLoading={isLoading || isUpdatingSalesRep}
      />
    </>
  );
};
