import { useEffect, useState } from 'react';
import { Box, Icon, TextInput, TextInputProps } from '@palmetto/palmetto-components';

export interface SearchInputProps extends Partial<TextInputProps> {
  searchText?: string;
  setSearchText(searchText?: string): void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

export function Search({
  searchText = '',
  setSearchText,
  placeholder,
  label = 'Search',
  disabled = false,
}: SearchInputProps): JSX.Element {
  const [enteredSearchText, setEnteredSearchText] = useState<string>(searchText);

  useEffect(() => {
    if (searchText !== enteredSearchText && (enteredSearchText.length >= 3 || enteredSearchText === '')) {
      const id = setTimeout(() => {
        setSearchText(enteredSearchText);
      }, 400);

      return () => {
        clearTimeout(id);
      };
    }
  }, [enteredSearchText, searchText, setSearchText]);

  return (
    <Box width={{ base: '3xl', desktop: '3xl' }}>
      <TextInput
        autoComplete={false}
        autoFocus={false}
        hideLabel
        id="searchInput"
        label={label}
        onChange={(e) => setEnteredSearchText(e.target.value)}
        onClear={() => setEnteredSearchText('')}
        value={enteredSearchText}
        minLength={3}
        placeholder={placeholder}
        size="sm"
        prefix={<Icon name="search" />}
        isDisabled={disabled}
      />
    </Box>
  );
}
