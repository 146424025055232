import { Button, Modal } from '@palmetto/palmetto-components';

export const AddTransactionToBatchModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  modalTitle,
  submitButtonText,
  modalId,
  isAddingBatchToTransaction,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => Promise<void>;
  modalTitle: string;
  submitButtonText: string;
  modalId: string;
  isAddingBatchToTransaction: boolean;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      maxWidth="4xl"
      ariaLabelledBy={modalId}
      onDismiss={() => {
        handleClose();
      }}
    >
      <Modal.Header
        id={modalId}
        title={modalTitle}
        onDismiss={() => {
          handleClose();
        }}
      />
      <Modal.Body background="secondary" childGap="lg">
        The selected transaction(s) will be added to the current batch. Are you sure you want to proceed?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          tone="neutral"
          isLoading={isAddingBatchToTransaction}
          isDisabled={isAddingBatchToTransaction}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          isLoading={isAddingBatchToTransaction}
          isDisabled={isAddingBatchToTransaction}
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          {submitButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
