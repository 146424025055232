import { SkeletonBox } from '@/components';
import { Box, Button, Card, Icon } from '@palmetto/palmetto-components';

interface OrganizationsListLoadingProps {
  rows: number;
}

export function OrganizationsListLoading({ rows }: OrganizationsListLoadingProps) {
  const displayRows = [];

  for (let i = 0; i < rows; i++) {
    displayRows.push(
      <Box
        key={`loading-row-${i}`}
        borderWidth="0 0 xs 0"
        borderColor="separator"
        direction="row"
        alignItems="center"
        padding="md lg xs 2xl"
        justifyContent="space-between"
        width="100%"
      >
        <Box direction="row" flex="auto" gap={'sm'}>
          <SkeletonBox height="16px" width="15%" margin="0 0 xs 0" />
          <SkeletonBox height="16px" width="10%" margin="0 0 xs 0" />
        </Box>
        <Box width="40px">
          <Button variant="tertiary" tone="neutral" size="md" isLoading></Button>
        </Box>
      </Box>,
    );
  }

  return <Card>{displayRows}</Card>;
}
