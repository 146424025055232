import {
  AccountDocument,
  AccountMilestoneStatus,
  AccountMilestoneWithRequirements,
  AccountRequirementDocument,
  AccountRequirementStatus,
  MilestoneRequirement,
  MilestoneType,
} from 'types';

export const isDomesticContentRequirementRejectedForAccount = (account: AccountDocument) => {
  const rejectedInstallMilestone = account.milestones?.find(
    (milestone: AccountMilestoneWithRequirements) =>
      milestone.type === MilestoneType.install && milestone.status === AccountMilestoneStatus.rejected,
  );

  if (rejectedInstallMilestone) {
    const installPackage = rejectedInstallMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.installPackage,
    );

    const isDomesticContentRequirementRejected = installPackage?.requirements.some(
      (req: AccountRequirementDocument) =>
        req.status === AccountRequirementStatus.rejected &&
        req.type === MilestoneRequirement.domesticContentConfirmation,
    );

    return isDomesticContentRequirementRejected;
  }
  return false;
};
