import FormSection from '@/components/Forms/FormSection';
import { FlagBox } from '@/components/QueueFlags/FlagBox';
import {
  Box,
  Button,
  FormikSelectInput,
  FormikTextInput,
  SimulatedEventPayloadType,
} from '@palmetto/palmetto-components';
import { FieldArray, Field, useFormikContext } from 'formik';
import { useMemo } from 'react';
import { HVACEquipmentTypeLabels } from 'types';

const EquipmentSection = ({ productsByClassification, userOnlyHasReadPermissions, userIsEditing = false }: any) => {
  const { values, setFieldValue } = useFormikContext<any>();

  const productSelectionOptions = useMemo(() => {
    if (!Object.keys(productsByClassification).length) {
      return {} as Record<string, { label: string; value: string }[]>;
    }
    const product: Record<string, { label: string; value: string }[]> = Object.keys(productsByClassification).reduce(
      (acc: any, key: string) => {
        acc[key] = productsByClassification[key].map((item: any) => ({
          label: `${item.manufacturer} - ${item.sku}`,
          value: item.id,
        }));
        return acc;
      },
      {} as Record<string, { label: string; value: string }[]>,
    );
    return product;
  }, [productsByClassification]);

  return (
    <FormSection
      title="Equipment and Materials"
      description={
        <Box gap="xs">
          <Box>Update applicable equipment and materials for the proposal.</Box>
          <Box>NOTE: any customer facing price changes require a new proposal from the sales platform.</Box>
        </Box>
      }
    >
      <FieldArray
        name="equipment"
        render={(arrayHelpers) => {
          return (
            <Box gap="lg">
              {values?.equipment?.map?.((e: any, index: number) => {
                return (
                  <Box
                    key={`${e.type}-${e.model}-${index}`}
                    direction="row"
                    gap="lg"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box direction="column" gap="sm" width="512px">
                      <Box>Equipment</Box>
                      <Box
                        fontSize="md"
                        background="default"
                        borderWidth="xs"
                        borderColor="default"
                        padding="xs"
                        radius="md"
                      >
                        {HVACEquipmentTypeLabels[e.type as keyof typeof HVACEquipmentTypeLabels]}
                      </Box>
                      <Box direction="row" gap="sm">
                        <Field
                          label="Equipment"
                          name={`equipment.${index}.option`}
                          id={`equipment.${index}.model`}
                          component={FormikSelectInput}
                          options={productSelectionOptions?.[e.type] || []}
                          defaultValue={e.option}
                          isDisabled={userOnlyHasReadPermissions}
                          onChange={(event: SimulatedEventPayloadType) => {
                            const selectedOption = event.target.value.value;
                            const selectedEquipment = productsByClassification?.[e.type].find(
                              (item: any) => item.id === selectedOption,
                            );
                            setFieldValue(`equipment.${index}.name`, selectedEquipment?.name);
                            setFieldValue(`equipment.${index}.manufacturer`, selectedEquipment?.manufacturer);
                            setFieldValue(`equipment.${index}.model`, selectedEquipment?.sku);
                            setFieldValue(`equipment.${index}.option`, event.target.value);
                            setFieldValue(`equipment.${index}.type`, selectedEquipment?.productType);
                            setFieldValue(`equipment.${index}.productId`, selectedEquipment?.id);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box direction="column" gap="sm" flex="auto">
                      <Box direction="row" gap="sm" justifyContent="space-between">
                        {userIsEditing && (
                          <Field
                            label="Serial Number"
                            name={`equipment.${index}.serialNumber`}
                            id={`equipment.${index}.serialNumber`}
                            component={FormikTextInput}
                            width="100"
                            isDisabled={userOnlyHasReadPermissions}
                          />
                        )}
                        <Field
                          label="Quantity"
                          type="number"
                          name={`equipment.${index}.quantity`}
                          id={`equipment.${index}.quantity`}
                          component={FormikTextInput}
                          maxWidth="lg"
                          width="100"
                          min={1}
                          isDisabled={userOnlyHasReadPermissions || userIsEditing}
                          onChange={(event: SimulatedEventPayloadType) => {
                            const value = parseInt(event.target.value, 10);
                            if (value < 1) {
                              setFieldValue(`equipment.${index}.quantity`, 1);
                            } else {
                              setFieldValue(`equipment.${index}.quantity`, value);
                              const originalCost = (e.cost ?? 0) / (e.quantity || 1);
                              setFieldValue(`equipment.${index}.cost`, originalCost * value);
                            }
                          }}
                        />
                        <Box direction="column" gap="lg">
                          <Field
                            label="Total Cost"
                            name={`equipment.${index}.cost`}
                            id={`equipment.${index}.cost`}
                            component={FormikTextInput}
                            value={e.cost}
                            prefix="$"
                            width="xl"
                            isDisabled={userOnlyHasReadPermissions || userIsEditing}
                            onFocus={(event: SimulatedEventPayloadType) => {
                              if (event.target.value === '0') {
                                setFieldValue(`equipment.${index}.cost`, '');
                              }
                            }}
                            onBlur={(event: SimulatedEventPayloadType) => {
                              const value = parseFloat(event.target.value);
                              setFieldValue(`equipment.${index}.cost`, isNaN(value) ? 0 : value);
                            }}
                            onChange={(event: SimulatedEventPayloadType) => {
                              const value = event.target.value;
                              if (value.endsWith('.')) {
                                setFieldValue(`equipment.${index}.cost`, value);
                              } else {
                                const numericValue = Number(value);
                                if (!isNaN(numericValue)) {
                                  setFieldValue(`equipment.${index}.cost`, value);
                                }
                              }
                            }}
                          />
                        </Box>
                        <Box padding="lg 0 0 0">
                          <Button
                            as="button"
                            variant="tertiary"
                            tone="danger"
                            isDisabled={userOnlyHasReadPermissions}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>
                      <Box direction="row" gap="sm">
                        <Box direction="column" gap="sm" justifyContent="flex-end">
                          <Box fontSize="md">&nbsp;</Box>
                          <Box fontSize="md">{e.name}</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              }) ?? null}
              <Box width="512px">
                <Field
                  label="Add Equipment"
                  name="newEquipmentType"
                  id="newEquipmentType"
                  placeholder="Select new equipment type..."
                  component={FormikSelectInput}
                  options={Object.keys(productsByClassification).map((key) => ({
                    label: HVACEquipmentTypeLabels[key as keyof typeof HVACEquipmentTypeLabels],
                    value: key,
                  }))}
                  isDisabled={userOnlyHasReadPermissions}
                  onChange={(event: SimulatedEventPayloadType) => {
                    const selectedOption = event.target.value.value;
                    arrayHelpers.push({
                      type: selectedOption,
                      manufacturer: '',
                      model: '',
                      quantity: 1,
                      cost: 0,
                    });
                    setFieldValue(`newEquipmentType`, '');
                  }}
                />
              </Box>
              {userIsEditing && <FlagBox baseCategory="equipment" dataKey="Installed Equipment Issue" />}
            </Box>
          );
        }}
      />
    </FormSection>
  );
};

export default EquipmentSection;
