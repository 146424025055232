import { Alert, Box, Button, Card, toast } from '@palmetto/palmetto-components';
import { Documents } from '../Documents';
import { ActivationPackagePhotoCategory, ActivationDocumentTypes } from 'types';
import FormSection from '../Forms/FormSection';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { isErrorWithData } from '../../services/helpers';
import { useGetTasksQuery } from '../../services/tasks';
import { useGetAccountQuery } from '../../services/accounts';
import { Task, TaskType, taskToMilestoneMap } from '../../types/Tasks';
import { MilestoneType } from '../../types/Milestone';
import { ActivationPackageActions } from '../../types/ActivationPackage';
import { taskTypeToActivationPhotoSection } from './taskTypeToDocumentType';
import { ActivationPhotos } from './ActivationPhotos';
import { useSubmitActivationPacketMutation } from '../../services/activationPacket';
import { Form, Formik } from 'formik';
import { MonitoringSiteIdSection } from './MonitoringSiteIdSection';
import { PTOConfirmationSection } from './PTOConfirmationSection';
import { MainContainer } from '../MainContainer';
import { MilestoneReviewStatus, QueueStatus, QueueType } from 'types';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import { convertToISODateString } from '../../helpers/dates';

const activationDocumentsDescription = (
  <Box color="body-secondary" fontSize="sm" childGap="2xs">
    <Box as="p">Upload all required incentives documents:</Box>
    <Box childGap="2xs" as="ul">
      <li>Extended Warranty</li>
      <li>Incentives</li>
    </Box>
  </Box>
);

const getMostRecentObj = (array: any[]) => {
  return array?.reduce((prev: any, current: any) => {
    return prev.date > current.date ? prev : current;
  });
};

export const LegacyActivationPackage = () => {
  const { id, action } = useParams<{ id: any; action: ActivationPackageActions }>();
  const { data: items = [], isLoading } = useGetTasksQuery({ id });
  const { data: account }: any = useGetAccountQuery(id);
  const { data: queue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.installPackage,
  });
  const [submitActivationPacket] = useSubmitActivationPacketMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const installNote = useMemo(() => {
    if (!account) {
      return;
    }

    if (queue && queue.status === QueueStatus.conditionallyApproved) {
      return getMostRecentObj(queue.approvalNotes)?.note;
    }

    return account?.notes?.milestones?.install;
  }, [account, queue]);

  const canSubmitActivationPackage = useMemo(() => {
    if (!account) return false;
    return account?.currentMilestone?.type === MilestoneType.activation || false;
  }, [account]);

  const initialValues = useMemo(
    () => ({
      monitoringSiteId: account?.monitoringSiteId || '',
      ptoGrantedDate: account?.ptoGrantedDate || '',
    }),
    [account],
  );
  const incompleteAdminTasks = useMemo(() => {
    return items.filter(
      (item) =>
        item.assigneeType === 'internal' &&
        item.status === 'ready' &&
        taskToMilestoneMap[item.type as TaskType] === MilestoneType.systemActivation,
    );
  }, [items]);

  const activationPhotoPartnerTasks = useMemo(() => {
    if (isLoading) return [];

    if (incompleteAdminTasks?.length === 0 && action === ActivationPackageActions.edit) {
      const partnerTasks = items.filter((item) => {
        return (
          item.assigneeType === 'partner' &&
          item.status === 'ready' &&
          taskToMilestoneMap[item.type as TaskType] === MilestoneType.systemActivation
        );
      });
      if (partnerTasks?.length) {
        let filesToUpload: ActivationPackagePhotoCategory[] = [];
        partnerTasks.forEach((task: any) => {
          if (taskTypeToActivationPhotoSection[task.type]) {
            filesToUpload.push(taskTypeToActivationPhotoSection[task.type]);
          }
        });
        return filesToUpload;
      }
    }

    return [];
  }, [items, action, isLoading, incompleteAdminTasks]);

  const rejectedTasks = useMemo(() => {
    if (!items) {
      return;
    }
    const internalActivationTasks = items.filter(
      (item: any) =>
        item.assigneeType === 'internal' &&
        taskToMilestoneMap[item.type as TaskType] === MilestoneType.systemActivation,
    );
    const groupedTasks = internalActivationTasks.reduce(
      (acc: any, task: any) => {
        acc[task.type] = acc[task.type] || [];
        acc[task.type].push(task);
        return acc;
      },
      {} as Record<TaskType, Task[]>,
    );
    return Object.keys(groupedTasks).reduce((acc, taskType) => {
      const tasks = groupedTasks[taskType as TaskType];
      if (!tasks.every((task: any) => task.rejectionReasons?.length && task.status === 'rejected')) {
        return acc;
      }
      acc.push(taskType);
      return acc;
    }, [] as string[]);
  }, [items]);

  useEffect(() => {
    if (!action || !ActivationPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
  }, [id, navigate, action]);

  const isActivationPendingReview = action === ActivationPackageActions.edit && !!incompleteAdminTasks.length;

  const handleSendRejectedPackageEmail = async () => {
    const ptoGrantedDate = account.ptoGrantedDate;
    const monitoringSiteId = account.monitoringSiteId;
    try {
      setIsSubmitting(true);
      await submitActivationPacket({
        accountId: id,
        data: {
          ptoGrantedDate: convertToISODateString(ptoGrantedDate) || '',
          monitoringSiteId,
          action,
          reviewStatus: MilestoneReviewStatus.rejected,
        },
      }).unwrap();

      toast.success('Activation Package Rejection email sent');
      navigate(`/accounts/${id}`);
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error sending email: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error sending email');
      }
    }
    setIsSubmitting(false);
  };

  const handleSubmit = async (values: any) => {
    // when adding submitActivationPacket for the new 'complete review' button, the account will be updated again
    // and values for ptoGrantedDate and monitoringSiteId will be undefined
    // to fix this, we will use the account values if the form values are undefined
    let ptoGrantedDate = values.ptoGrantedDate;
    if (!ptoGrantedDate) {
      ptoGrantedDate = account.ptoGrantedDate;
    }
    let monitoringSiteId = values.monitoringSiteId;
    if (!monitoringSiteId) {
      monitoringSiteId = account.monitoringSiteId;
    }
    try {
      setIsSubmitting(true);
      await submitActivationPacket({
        accountId: id,
        data: { ...values, ptoGrantedDate: new Date(ptoGrantedDate), monitoringSiteId, action },
      }).unwrap();

      if (action !== ActivationPackageActions.review) {
        toast.success('Activation Package submitted successfully');
        navigate(`/accounts/${id}`);
      }
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error submitting package: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error submitting package');
      }
    }
    setIsSubmitting(false);
  };

  const handleValidation = (values: any) => {
    const errors = {} as any;
    const teslaInverters = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const otherInverters = /^\d{5,8}$/;
    if (!values.ptoGrantedDate) {
      errors.ptoGrantedDate = 'PTO Granted Date is required';
    }
    if (!values.monitoringSiteId.trim()) {
      errors.monitoringSiteId = 'A Monitoring Site Id is required';
    } else if (!teslaInverters.test(values.monitoringSiteId) && !otherInverters.test(values.monitoringSiteId)) {
      errors.monitoringSiteId =
        'Monitoring Site Id must match the required formats: ' +
        '5-8 numbers long for Enphase, SolarEdge, and Tigo. ' +
        'The site ID for Tesla should come from the URL (ex: 8af307d1-ca9f-4843-8079-df2ba9c5736c from https://powerhub.energy.tesla.com/site/8af307d1-ca9f-4843-8079-df2ba9c5736c).';
    }

    return errors;
  };

  return (
    <>
      <MainContainer>
        {isActivationPendingReview && (
          <Alert
            variant="warning"
            hasIcon
            title="Activation Package Pending Review"
            message="The Activation Package is pending review. The review must be completed before resubmitting."
          />
        )}
        {installNote && <Alert variant="info" hasIcon title="Install Notes" message={installNote} />}
        <Box padding={{ base: '0 lg', tablet: '0' }}>
          <Formik
            initialValues={initialValues}
            validate={handleValidation}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <Form noValidate id="activationMilestoneForm">
              <Box>
                <MonitoringSiteIdSection />
              </Box>
              <Box>
                <PTOConfirmationSection isActivationPendingReview={isActivationPendingReview} />
              </Box>
            </Form>
          </Formik>
          {/* @ts-ignore pass component to description */}
          <FormSection title="Incentives Documents" description={activationDocumentsDescription}>
            <Documents
              documentTypeFilter={[ActivationDocumentTypes.extendedWarranty, ActivationDocumentTypes.incentives]}
              showDocumentActions={action === ActivationPackageActions.review}
              allowArchive={false}
              title="Incentives"
              showUploadButton={action !== ActivationPackageActions.review && !isActivationPendingReview}
              originContext={QueueType.activationPackage}
            />
          </FormSection>

          {/* @ts-ignore pass component to description */}
          <FormSection
            title="Site Improvements"
            description="If applicable, photos of site improvements made such as tree removal or main panel upgrade."
          >
            <Card>
              <ActivationPhotos
                partnerReviewTaskSections={activationPhotoPartnerTasks}
                isActivationPendingReview={isActivationPendingReview}
              />
            </Card>
          </FormSection>
          {rejectedTasks && rejectedTasks.length > 0 && action === ActivationPackageActions.review && (
            <Box
              direction={{
                base: 'column',
                tablet: 'row',
              }}
              alignItems={{
                base: 'center',
                tablet: 'center',
              }}
              justifyContent={{
                tablet: 'flex-end',
              }}
              childGap="sm"
              style={{ flexShrink: 0 }}
              padding="lg 0 0 0"
            >
              <Box alignItems="center" color="danger">
                {`${rejectedTasks.length} ${rejectedTasks.length > 1 ? 'items are' : 'item is'} rejected`}
              </Box>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                disabled={isSubmitting}
                onClick={handleSendRejectedPackageEmail}
              >
                Send Package Rejection Email
              </Button>
            </Box>
          )}
          {(action === ActivationPackageActions.create || action === ActivationPackageActions.edit) && (
            <Box
              direction={{
                base: 'column',
                tablet: 'row',
              }}
              alignItems={{
                base: 'stretch',
                tablet: 'flex-end',
              }}
              justifyContent={{
                tablet: 'flex-end',
              }}
              childGap="sm"
              style={{ flexShrink: 0 }}
              padding="lg 0 0 0"
            >
              <Button
                as="a"
                navigate={() => navigate(`/accounts/${id}`)}
                variant="secondary"
                tone="neutral"
                isLoading={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                form="activationMilestoneForm"
                isDisabled={!!incompleteAdminTasks?.length || !canSubmitActivationPackage}
                isLoading={isSubmitting}
                variant="primary"
              >
                Submit Activation Package
              </Button>
            </Box>
          )}
        </Box>
      </MainContainer>
    </>
  );
};
