import { AccountDocument } from 'types';
import { isDomesticContentRequirementRejectedForAccount } from './isDomesticContentRequirementRejectedForAccount';

export const getNavigatePathForUpdateInstallationPackage = (account: AccountDocument) => {
  const isDomesticContentEligible = account?.itcAdderQualifications?.domesticContentEligible;
  if (isDomesticContentEligible && isDomesticContentRequirementRejectedForAccount(account)) {
    return `/accounts/${account.id}/domestic-content-value-confirmation`;
  }
  return `/accounts/${account.id}/installation-package/edit`;
};
