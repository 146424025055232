import { Box } from '@palmetto/palmetto-components';
import { SystemDesign } from 'types';

type IInstallationData = SystemDesign & {
  inverterModels: {
    model: string;
    count: number;
  }[];
};

export const EquipmentListSection = ({ installationData }: { installationData: IInstallationData }) => {
  return (
    <Box>
      <Box
        style={{
          marginTop: '2em',
        }}
      >
        <Box as="h3">Installed Equipment</Box>
        <br />
      </Box>
      <Box>
        <table>
          <tbody>
            <tr>
              <td>Mounting Manufacturer:</td>
              <td>{installationData.mountingManufacturer}</td>
            </tr>
            <tr>
              <td>Panel Manufacturer:</td>
              <td>{installationData.panelManufacturer}</td>
            </tr>
            <tr>
              <td>Panel Model:</td>
              <td>{installationData.panelModel}</td>
            </tr>
            <tr>
              <td>Inverter Manufacturer:</td>
              <td>{installationData.inverterManufacturer}</td>
            </tr>
            {installationData.inverterModels.map((element: any, index: number) => (
              <tr>
                <td>Inverter Model</td>
                <td>{element.model}</td>
              </tr>
            ))}
            {installationData.storage?.map((element) => (
              <tr>
                <td>Storage Manufacturer</td>
                <td>{element.manufacturer}</td>
                <td>Storage Model</td>
                <td>{element.model}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};
