import { Box, Button, Details, Icon, useOpenClose } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';

const OrganizationListRow = ({
  item,
  level,
  isSearchingByUser,
}: {
  item: any;
  level: any;
  isSearchingByUser: boolean;
}) => {
  const { isOpen, handleToggle } = useOpenClose({
    defaultIsOpen: false,
  });
  return (
    <Box>
      <Box
        borderColor="separator"
        direction="row"
        justifyContent="space-between"
        borderWidth="0 0 xs 0"
        alignItems="center"
        hover={{ background: 'secondary' }}
      >
        <Details isOpen={isOpen} style={{ marginLeft: `${level * 25}px` }} margin="sm" padding="0 0 0 sm">
          <Details.Summary isDetailsOpen={isOpen} onToggle={handleToggle}>
            <Box direction="row" childGap="sm" alignItems="center">
              {!isSearchingByUser && <Icon name={isOpen ? 'caret-sm-down' : 'caret-sm-right'} color="grey-300" />}
              <Box direction="row" alignItems="baseline" childGap="2xs">
                <Box fontWeight="medium">{item.name}</Box>
                <Box color="body-secondary" fontSize="xs">
                  {item.alias}
                </Box>
              </Box>
            </Box>
          </Details.Summary>

          {!isSearchingByUser && (
            <Box padding="md 0 0 lg">
              <Box fontWeight="medium">Can see: </Box>
              <Box>
                {Array.isArray(item.visibleOrganizationAliases)
                  ? item.visibleOrganizationAliases.join(', ')
                  : 'No visible organizations'}
              </Box>
            </Box>
          )}
        </Details>
        <Box margin="xs lg xs">
          <Link to={`/settings/organizations/${item.alias}`}>
            <Button
              iconPrefix="settings"
              aria-label="organization settings"
              size="sm"
              variant="secondary"
              tone="neutral"
            />
          </Link>
        </Box>
      </Box>

      <Box>
        {item.children?.map((child: any) => (
          <OrganizationListRow key={child.id} item={child} level={level + 1} isSearchingByUser={isSearchingByUser} />
        ))}
      </Box>
    </Box>
  );
};

export default OrganizationListRow;
