import { IconName } from '@palmetto/palmetto-components';
import { DateTime } from 'luxon';
import { QueueHistory, QueueNote, QueueStatus, QueueType } from 'types';

const mapNote = (note: QueueNote) => {
  const dateTime = DateTime.fromISO(`${note.date}`);
  return {
    title: `${note.user.name} left a note`,
    date: dateTime.toLocaleString(DateTime.DATE_FULL),
    time: dateTime.toLocaleString(DateTime.TIME_SIMPLE),
    detail: `Note: ${note.note}`,
    icon: 'note' as IconName,
  };
};

const getTitleForStatusChange = (history: QueueHistory, packageName: string) => {
  switch (history.changedTo) {
    case QueueStatus.approved:
      return `${history.user.name} Approved ${packageName}`;
    case QueueStatus.rejected:
      return `${history.user.name} Rejected ${packageName}`;
    case QueueStatus.resubmitted:
      return `${history.user.name} Submitted for ${packageName} for Re-Review`;
    case QueueStatus.submitted:
      return `${history.user.name} Submitted for ${packageName} Review`;
    case QueueStatus.onHold:
      return `${history.user.name} Put ${packageName} on Hold`;
    case QueueStatus.conditionallyApproved:
      return `${history.user.name} Conditionally Approved ${packageName}`;
    default:
      return '';
  }
};

const statusChangeIconMap: Record<QueueStatus, IconName> = {
  [QueueStatus.approved]: 'c-check',
  [QueueStatus.rejected]: 'c-remove',
  [QueueStatus.resubmitted]: 'arrow-right',
  [QueueStatus.submitted]: 'arrow-right',
  [QueueStatus.onHold]: 'pause',
  [QueueStatus.conditionallyApproved]: 'c-check',
  [QueueStatus.inProgress]: 'play',
};

const packageNameTitleMap: Partial<Record<QueueType, string>> = {
  [QueueType.domesticContent]: 'Domestic Content',
  [QueueType.installPackage]: 'Install Package',
  [QueueType.activationPackage]: 'Activation Package',
  [QueueType.permissionToOperate]: 'PTO Package',
  [QueueType.electricianSignOff]: 'Electrician Sign-Off',
};

const getHistoryTitle = (history: QueueHistory, queue: any): string => {
  switch (history.action) {
    case 'status':
      return getTitleForStatusChange(
        history,
        queue?.type ? (packageNameTitleMap[queue.type as QueueType] ?? 'Package') : '',
      );
    case 'claimedBy': {
      if (history.changedTo === null) {
        return `${history.user.name} Unclaimed the package`;
      } else {
        return `${history.user.name} Claimed package for review`;
      }
    }
    case 'flag':
      let category: string =
        (queue?.flags || []).find((element: any) => {
          return history.dataKey === element.dataKey;
        })?.category || null;
      let originString: string = '';
      if (!category) {
        originString = history.fileName || history.dataKey || '';
      } else {
        originString = `${category} -> ${history.fileName}`;
      }
      return `${history.user.name} ${history.changedTo} ${originString}`;
    default:
      return '';
  }
};

const getHistoryIcon = (history: QueueHistory): IconName => {
  switch (history.action) {
    case 'status':
      return statusChangeIconMap[history.changedTo as QueueStatus];
    case 'claimedBy':
      return 'c-user';
    case 'flag':
      return 'flag';
    default:
      return 'question-mark';
  }
};

const getHistoryDetail = (history: QueueHistory): string => {
  let detail = '';
  if (history.reasons) {
    detail += history.reasons.map((reason) => `Reason: ${reason}`).join('<br/>') + '<br/>';
  }
  if (history.note) {
    detail += `Note: ${history.note}`;
  }
  return detail;
};

const mapHistory = (history: QueueHistory, queue: any) => {
  const dateTime = DateTime.fromISO(`${history.date}`);
  return {
    title: getHistoryTitle(history, queue),
    date: dateTime.toLocaleString(DateTime.DATE_FULL),
    time: dateTime.toLocaleString(DateTime.TIME_SIMPLE),
    icon: getHistoryIcon(history),
    detail: getHistoryDetail(history),
  };
};

export const mapQueueHistoryOrNote = (item: QueueHistory | QueueNote, queue: any) => {
  if ('action' in item) {
    return mapHistory(item, queue);
  }
  return mapNote(item);
};
