import { currencyFormatter } from '@/helpers/currencyFormatter';
import { Box, DimensionSize, Popover } from '@palmetto/palmetto-components';
import { useState } from 'react';
import { Quote } from 'types';

export interface ListItemElementProps {
  label: string;
  value: string;
}

export interface ListItemElementPropsWithTooltip extends ListItemElementProps {
  customId: string;
  cssCustomStyle?: {
    label?: any;
    value?: any;
  };
  tooltipText: string | null;
}

export const getDomesticElements = ({
  quote,
  totalSystemCost,
  totalAdderCost,
  pricePerWatt,
}: {
  quote: Quote;
  totalSystemCost: number;
  totalAdderCost: number;
  pricePerWatt: number;
}) => {
  let epcTotalPaymentDomesticContentElements: ListItemElementPropsWithTooltip[] = [];
  let epcRatePPWDomesticContentElements: ListItemElementPropsWithTooltip[] = [];
  let totalEPCPaymentElements: ListItemElementPropsWithTooltip[] = [];
  const tooltipText = 'Projects meeting the IRA Domestic Content Threshold receive a payment boost.';
  if (quote.domesticContentEligible) {
    epcTotalPaymentDomesticContentElements = [
      {
        label: 'DC Boost',
        tooltipText,
        value: `${currencyFormatter.format(totalSystemCost - totalAdderCost)}`,
        customId: 'epcTotalPaymentDomesticElementWithDC',
      },
      {
        label: 'Non-DC',
        tooltipText: null,
        value: `${currencyFormatter.format(quote.totalSystemCostWithoutDomesticContent - totalAdderCost)}`,
        customId: 'epcTotalPaymentDomesticElementWithoutDC',
        cssCustomStyle: {
          label: {
            color: '#8C919B',
          },
          value: {
            color: '#8C919B',
          },
        },
      },
    ];
    epcRatePPWDomesticContentElements = [
      {
        label: 'DC Boost',
        tooltipText,
        value: `${currencyFormatter.format(pricePerWatt)}`,
        customId: 'epcRatePPWDomesticElementWithDC',
      },
      {
        label: 'Non-DC',
        tooltipText: null,
        value: `${currencyFormatter.format(quote.pricePerWattWithoutDomesticContent)}`,
        customId: 'epcRatePPWDomesticElementWithoutDC',
        cssCustomStyle: {
          label: {
            color: '#8C919B',
          },
          value: {
            color: '#8C919B',
          },
        },
      },
    ];
    totalEPCPaymentElements = [
      {
        label: 'DC Boost',
        tooltipText,
        value: `${currencyFormatter.format(totalSystemCost)}`,
        customId: 'epcRatePPWDomesticElementWithDC',
      },
      {
        label: 'Non-DC',
        tooltipText: null,
        value: `${currencyFormatter.format(quote.totalSystemCostWithoutDomesticContent)}`,
        customId: 'epcRatePPWDomesticElementWithoutDC',
        cssCustomStyle: {
          label: {
            color: '#8C919B',
          },
          value: {
            color: '#8C919B',
          },
        },
      },
    ];
  }
  return {
    epcTotalPaymentDomesticContentElements,
    epcRatePPWDomesticContentElements,
    totalEPCPaymentElements,
  };
};

export function TooltipElement({
  content,
  onClose,
  children,
}: {
  content: string | null;
  onClose: () => void;
  children: any;
}) {
  const hasContent = !!content;
  const [open, setIsOpen] = useState(false);
  return (
    <Popover
      content={
        <div
          style={{
            background: 'white',
            padding: '1em',
            fontSize: '16px',
            textAlign: 'left',
            color: '#44474B',
          }}
        >
          {content}
        </div>
      }
      isOpen={!hasContent ? false : open}
      placement="bottom-end"
      offsetFromTarget={8}
      contentContainerProps={{
        padding: '0',
        width: '490px',
        shadow: 'lg',
        radius: 'sm',
        overflow: 'hidden',
        background: 'white',
      }}
      onClickOutside={onClose}
    >
      <div
        onMouseEnter={() => {
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
      >
        {children}
      </div>
    </Popover>
  );
}

export function ListItemElementWithMultipleProps({
  baseLabel,
  values,
}: {
  baseLabel: string;
  values: ListItemElementPropsWithTooltip[];
}) {
  return (
    <Box direction="row" padding="0 lg" gap="5xl" style={{
      lineHeight: "1.5em"
    }}>
      <Box width={{ base: 'xs' as DimensionSize, tablet: 'lg' }} />
      <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
        <Box flex="auto">{baseLabel}</Box>
        <Box direction={'row'}>
          {values.map((listElementValue, index) => {
            return (
              <TooltipElement key={listElementValue.value} content={listElementValue.tooltipText} onClose={() => {}}>
                <Box
                  style={{
                    minWidth: "6em",
                    borderRight: index != (values.length - 1) ? '1px solid #E0E2E7' : '0',
                    paddingRight: index != (values.length - 1) ? '1em' : '0',
                    cursor: "default"
                  }}
                  className={listElementValue.customId}
                >
                  <Box
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      textUnderlinePosition: 'from-font',
                      textDecorationSkipInk: 'none',
                      textAlign: 'right',
                      marginBottom: '0.2em',
                      ...(listElementValue.cssCustomStyle?.label ?? {}),
                    }}
                  >
                    {listElementValue.label}
                  </Box>
                  <Box
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                      textAlign: 'right',
                      textDecorationLine: 'underline',
                      textUnderlineOffset: '25%',
                      textDecorationThickness: '11%',
                      textUnderlinePosition: 'from-font',
                      textDecorationSkipInk: 'none',
                      ...(listElementValue.cssCustomStyle?.value ?? {}),
                    }}
                  >
                    {listElementValue.value}
                  </Box>
                </Box>
              </TooltipElement>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export function ListItemElement({ label, value }: ListItemElementProps) {
  return (
    <Box direction="row" padding="0 lg" gap="5xl">
      <Box width={{ base: 'xs' as DimensionSize, tablet: 'lg' }} />
      <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
        <Box flex="auto">{label}</Box>
        <Box>{value}</Box>
      </Box>
    </Box>
  );
}

export const getEpcTotalPaymentLabel = ({
  hasArbitrageBattery,
  hasBackupBatteryKWH,
  isHVACQuote,
}: {
  hasArbitrageBattery?: boolean;
  hasBackupBatteryKWH: boolean;
  isHVACQuote: boolean;
}) => {
  return hasArbitrageBattery
    ? 'Solar with Arbitrage Battery EPC Total Payment'
    : hasBackupBatteryKWH
      ? 'Solar with Backup Battery EPC Total Payment'
      : !isHVACQuote
        ? 'Solar-only EPC Total Payment'
        : 'EPC Total Payment';
};

export const getEpcRatePPWLabel = ({
  hasArbitrageBattery,
  hasBackupBatteryKWH,
}: {
  hasArbitrageBattery?: boolean;
  hasBackupBatteryKWH: boolean;
}) => {
  return hasArbitrageBattery
    ? 'Solar with Arbitrage Battery EPC Rate ($/W)'
    : hasBackupBatteryKWH
      ? 'Solar with Backup Battery EPC Rate ($/W)'
      : 'Solar-only EPC Rate ($/W)';
};
