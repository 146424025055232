import { useParams } from 'react-router-dom';
import { QueueRejectionReason, QueueStatus, QueueType } from 'types';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { QueueBanner } from '../MilestonePackagePartials/QueueBanner';
import { NotesDrawer } from '../NotesDrawer/NotesDrawer';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { useAuth } from '../auth/authProvider';
import { useGetQueueByAccountIdAndTypeQuery, useUpdateQueueMutation } from '../../services/queues';
import { DocumentRejectionModal } from '../DocumentRejectionModal/DocumentRejectionModal';
import { isErrorWithData } from '../../services/helpers';
import { Box, Button, toast, useOpenClose } from '@palmetto/palmetto-components';
import { useState } from 'react';

export const DomesticContentReview = () => {
  const { claims, user } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const { id = '', action = 'review' } = useParams<{ id: string; action: ReviewPackageActions }>();
  const { data: domesticContentQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.domesticContent,
  });
  const [updateQueue, { isLoading: isUpdatingQueue }] = useUpdateQueueMutation();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const handleQueueUpdate = async (status: QueueStatus, note?: string, reasons?: QueueRejectionReason[]) => {
    try {
      if (domesticContentQueue?.id) {
        await updateQueue({
          id: domesticContentQueue.id,
          data: {
            assigneeId: user?.sub,
            status,
            ...(reasons && {
              rejectionReasons: [
                {
                  reasons,
                  ...(note && { note }),
                },
              ],
            }),
          },
        }).unwrap();
        toast.success('Queue updated successfully');
      }
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating queue: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating queue');
      }
    }
  };

  const approve = async () => {
    return handleQueueUpdate(QueueStatus.approved);
  };

  const handleRejectModal = async (values: { rejectionReason: string; notes: string | undefined }) => {
    const { rejectionReason, notes } = values;
    await handleQueueUpdate(QueueStatus.rejected, notes, [rejectionReason as QueueRejectionReason]);
    closeModal();
  };

  return domesticContentQueue && (domesticContentQueue.status === QueueStatus.rejected || isFinCoUser) ? (
    <>
      <DocumentRejectionModal
        isModalOpen={isModalOpen}
        title="Domestic Content"
        handleClose={closeModal}
        handleSubmit={handleRejectModal}
      />
      <NotesDrawer isOpen={isNotesOpen} setIsOpen={setIsNotesOpen} queueType={QueueType.domesticContent} />
      <Box padding="md" margin="0 0 lg 0" borderColor="separator" borderWidth="xs" gap="sm">
        <h3>Domestic Content Review</h3>
        <QueueBanner
          setIsNotesOpen={setIsNotesOpen}
          queueType={QueueType.domesticContent}
          isFinCoUser={isFinCoUser}
        />
        {action === ReviewPackageActions.review && domesticContentQueue.status !== QueueStatus.approved && (
          <Box direction="row" childGap="xs" justifyContent="flex-end" alignItems="flex-end">
            <Button size="sm" variant="primary" isLoading={isUpdatingQueue} onClick={approve}>
              Approve
            </Button>
            <Button size="sm" variant="primary" tone="danger" isLoading={isUpdatingQueue} onClick={openModal}>
              Reject
            </Button>
          </Box>
        )}
      </Box>
    </>
  ) : null;
};
