import { Helmet } from 'react-helmet';
import { Box, Button, Card, RadioGroup, useBreakpoint } from '@palmetto/palmetto-components';
import {
  useGetOrganizationListFiltersQuery,
  useGetOrganizationTreeQuery,
  useLazyGetOrganizationTreeQuery,
  useLazySearchOrganizationsByUserQuery,
} from '../../../services/organizations';
import PageHeader from '../../PageHeader';
import RequirePermissions from '../../auth/requirePermissions';
import OrganizationListRow from './OrganizationListRow';
import { MainContainer } from '../../MainContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FilterBar } from '@/components/filters';
import { OrganizationsListLoading } from './components/OrganizationListLoading';
import { AddFilterButtonDropdown } from '@/components/filters/AddFilterButton';
import { useCallback, useEffect, useState } from 'react';
import { AppliedDataFilter } from '@/components/filters/FilterButton';

export function Organizations() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([]);
  const [filteredTree, setFilteredTree] = useState<any[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearchingByUser, setIsSearchingByUser] = useState(false);
  const [searchType, setSearchType] = useState('organization');

  const advancedFilters = searchParams.get('advancedFilters');
  const searchTerm = searchParams.get('search') || '';

  const { data: filters = [], isLoading: isFiltersLoading } = useGetOrganizationListFiltersQuery({});
  const [triggerOrganizationTreeQuery, { data: treeData, isLoading: isOrganizationTreeLoading }] =
    useLazyGetOrganizationTreeQuery();
  const [triggerUserSearch, { data: userOrganizationsData, isLoading: isUserOrganizationsLoading }] =
    useLazySearchOrganizationsByUserQuery();

  const { isPhone } = useBreakpoint();
  const navigate = useNavigate();

  const isLoading = isOrganizationTreeLoading || isUserOrganizationsLoading || isSearchLoading;

  const onSearchChange = (searchTerm: string) => {
    setFilteredTree([]);
    setIsSearchLoading(true);
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    setSearchParams(searchParams);
  };

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  const handleSearchTypeChange = (event: any) => {
    const newSearchType = event.target.value;
    setSearchType(newSearchType);
    setFilteredTree([]);
    setIsSearchLoading(true);
    onSearchChange('');
    triggerOrganizationTreeQuery({ search: '', advancedFilters: advancedFilters || JSON.stringify([]) }).finally(
      () => setIsSearchLoading(false),
    );
  };

  useEffect(() => {
    if (searchType === 'user' && !searchTerm.trim()) {
      setFilteredTree(treeData?.tree || []);
      setIsSearchLoading(false);
    } else if (searchType === 'user' && searchTerm.trim() && userOrganizationsData) {
      setFilteredTree(userOrganizationsData.organizations);
      setIsSearchLoading(false);
    } else if (searchType === 'organization' && treeData) {
      setFilteredTree(treeData.tree);
      setIsSearchLoading(false);
    }
  }, [searchType, searchTerm, userOrganizationsData, treeData]);

  useEffect(() => {
    const filters = advancedFilters || JSON.stringify([]);
    setAppliedFilters(JSON.parse(filters));

    if (searchType === 'organization') {
      setIsSearchLoading(true);
      triggerOrganizationTreeQuery({ search: searchTerm, advancedFilters: filters }).finally(() =>
        setIsSearchLoading(false),
      );
      setIsSearchingByUser(false);
    } else if (searchType === 'user' && searchTerm.trim()) {
      setIsSearchLoading(true);
      triggerUserSearch({ search: searchTerm }).finally(() => setIsSearchLoading(false));
      setIsSearchingByUser(true);
    }
  }, [searchType, searchTerm, advancedFilters, triggerOrganizationTreeQuery, triggerUserSearch]);

  return (
    <>
      <Helmet>
        <title>Organizations | Settings</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Organizations" description="All visible organizations for the current organization">
          <RequirePermissions permissions={['admin']}>
            <Button
              as="a"
              navigate={() => navigate('/settings/organizations/new')}
              variant="primary"
              size={{ base: 'sm', desktop: 'md' }}
              aria-label="create Organization"
              iconPrefix="add"
            >
              {!isPhone && 'Organization'}
            </Button>
          </RequirePermissions>
        </PageHeader>
        <Box>
          <Box background="tertiary" radius="md md 0 0">
            <FilterBar
              filtersState={{
                searchTerm,
              }}
              handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
              placeholder={searchType === 'organization' ? 'Search org by name or alias' : 'Search org by user'}
            >
              <Box direction="row" style={{ flexGrow: 1 }}>
                {!isFiltersLoading && searchType !== 'user' && (
                  <AddFilterButtonDropdown
                    dataFilters={filters}
                    appliedFilters={appliedFilters}
                    onRemoveFilter={handleRemoveAdvancedFilter}
                    onApplyFilters={handleApplyAdvancedFilters}
                    onClearFilters={handleClearAdvancedFilters}
                  />
                )}
              </Box>
            </FilterBar>
            <Box margin="md md md lg">
              <RadioGroup
                title="Search By"
                direction="row"
                name="searchType"
                onChange={handleSearchTypeChange}
                value={searchType}
                options={[
                  { id: 'organization', value: 'organization', label: 'Organization' },
                  { id: 'user', value: 'user', label: 'User' },
                ]}
              />
            </Box>
          </Box>
          {isLoading ? (
            <Card radius="0 0 md md">
              <OrganizationsListLoading rows={25} />
            </Card>
          ) : filteredTree && filteredTree.length ? (
            <Card radius="0 0 md md">
              {filteredTree.map((item, index) => (
                <OrganizationListRow
                  key={`${item.id}-${index}`}
                  item={item}
                  level={0}
                  isSearchingByUser={isSearchingByUser}
                />
              ))}
            </Card>
          ) : (
            <Card radius="0 0 md md" padding="lg" textAlign="center">
              {searchType === 'organization'
                ? 'No organizations match the current search and/or filter(s)'
                : 'No organization match searched user'}
            </Card>
          )}
        </Box>
      </MainContainer>
    </>
  );
}
