import { IdProviderUser, SalesRep, SalesRepStatus } from 'types';
import { api } from './api';

interface SalesRepSearchResponse {
  pageNum: number;
  total: number;
  salesReps: SalesRep[];
}

export const salesRepApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSalesReps: build.query<SalesRepSearchResponse, Record<any, any>>({
      query: (queryParams) => {
        return {
          url: `sales-reps${
            queryParams &&
            '?' +
              new URLSearchParams({
                ...queryParams,
              })
          }`,
        };
      },
      transformResponse: (response: any) => {
        const { data, pageNum, total } = response || {};
        return {
          pageNum,
          total,
          salesReps: data?.salesReps,
        };
      },
      providesTags: () => [{ type: 'SalesReps', id: 'LIST' }],
    }),
    getSalesRep: build.query<SalesRep, { id: string }>({
      query: ({ id }) => ({ url: `sales-reps/${id}` }),
      providesTags: (_result, _error, { id }) => [{ type: 'SalesReps', id }],
    }),
    updateSalesRep: build.mutation<SalesRep, { id: string; status: SalesRepStatus }>({
      query: ({ id, status }) => ({
        url: `sales-reps/${id}`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'SalesReps', id: 'LIST' },
        { type: 'SalesReps', id },
      ],
    }),
    getSalesRepProviderUserBySalesRepId: build.query<IdProviderUser[], { salesRepId: string }>({
      query: ({ salesRepId }) => ({
        url: `sales-reps/${salesRepId}/provider-user`,
      }),
    }),
    getSalesRepListFilters: build.query<any, void>({
      query: () => {
        return {
          url: `sales-reps/filters`,
        };
      },
    }),
  }),
});

export const {
  useGetSalesRepsQuery,
  useLazyGetSalesRepsQuery,
  useGetSalesRepListFiltersQuery,
  useUpdateSalesRepMutation,
  useGetSalesRepQuery,
  useGetSalesRepProviderUserBySalesRepIdQuery,
} = salesRepApi;
