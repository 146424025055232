import {
  PaymentSettingsWithMetadata,
  FinancialAccountRegister,
  PriceSheetMapping,
  EmailSetting,
  OrganizationCEDSettings,
  FinancialAccountDocument,
  ProgramType,
  PaymentPlan,
  OrganizationType,
  PayoutHold,
  DeepPartial,
} from 'types';
import { Auth0PalmettoFinanceUser, FlatOrganization, OrganizationNode } from '@/types/Organizations';
import { api } from './api';

interface CreateOrganizationParams {
  alias: string;
  parentAlias: string;
  name: string;
  email?: string;
  phoneNumber?: string;
  federalEmployerId?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  licenses?: [
    { state: string; contractorLicenseNumber: string; additionalLicenseNumbers: string; monetaryLimit: number },
  ];
  insuranceCompanyName?: string;
  insuranceCompanyPhoneNumber?: string;
  programTypes?: ProgramType[];
  organizationTypes?: OrganizationType[];
  extendedWarrantyDealerInformation?: [{ warrantyProvider: string; dealerLicenseNumber: string }];
}

interface GetOrganizationQueryParams {
  includeUsers?: boolean;
  useRootOrganization?: boolean;
  usersPage?: number;
  usersLimit?: number;
  search?: string;
  programType?: string;
  state?: string;
  advancedFilters?: string;
}

interface GetOrganizationDetailsQueryParams {
  alias: string;
  includeUsers?: boolean;
  usersPage?: number;
  usersLimit?: number;
  inviteToken?: string;
}

interface GetOrganizationPaymentSchedule {
  alias: string;
}

interface GetOrganizationEmailSettings {
  alias: string;
}

interface GetOrganizationSplitPaySettings {
  alias: string;
}

interface GetOrganizationPaymentSetting {
  alias: string;
}

interface SplitPayProgramNamesResponse {
  programs: {
    programId: string;
    programName: string;
  }[];
}
interface PutOrganizationPaymentSetting {
  alias: string;
  paymentSettings: DeepPartial<{
    paymentPlanId: string;
    netSuiteVendorId: string;
    inheritBankAccount: boolean;
    inheritNetSuiteVendorId: boolean;
    inheritPaymentPlan: boolean;
    payoutHold: Pick<PayoutHold, 'active' | 'reason'>;
  }>;
}

interface SearchUsersResponse {
  meta: {
    total: number;
    limit: number;
    currentPage: number;
    prevPage?: number;
    nextPage?: number;
  };
  data: Auth0PalmettoFinanceUser[];
}
interface SearchUsersParams {
  orgAlias: string;
  searchUsers: string;
  page?: number;
  limit?: number;
}

export const flattenOrganizationTree = (organization: OrganizationNode, path = ''): FlatOrganization[] => {
  path += organization.alias + '/';
  const children = organization.children.flatMap((o) => flattenOrganizationTree(o, path));
  return [{ ...organization, path }, ...children];
};

export const organizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationTree: build.query<
      { tree: OrganizationNode[]; flat: FlatOrganization[] },
      GetOrganizationQueryParams | void
    >({
      query: (queryParams) => {
        const formattedParams = Object.fromEntries(
          Object.entries(queryParams || {}).map(([key, value]) => [key, String(value)]),
        );
        return {
          url: `organizations${queryParams ? '?' + new URLSearchParams({ ...formattedParams }) : ''}`,
        };
      },
      transformResponse(baseQueryReturnValue: OrganizationNode[]) {
        if (!baseQueryReturnValue.length) {
          return { tree: [], flat: [] };
        }
        const flat = flattenOrganizationTree(baseQueryReturnValue[0]);
        return { tree: baseQueryReturnValue, flat };
      },
      providesTags: () => {
        return [{ type: 'Organizations', id: 'LIST' }];
      },
    }),
    getOrganizationDetails: build.query<OrganizationNode, GetOrganizationDetailsQueryParams>({
      query: ({ alias, usersPage = 1, usersLimit = 10, includeUsers = false, inviteToken }) => ({
        url: `organizations/${alias}?includeUsers=${includeUsers}&usersPage=${usersPage}&usersLimit=${usersLimit}${inviteToken ? `&inviteToken=${inviteToken}` : ''}`,
      }),
      providesTags: (result) => [{ type: 'Organizations', id: result?.alias }],
    }),
    createOrganization: build.mutation<OrganizationNode, CreateOrganizationParams>({
      query: (organization) => ({
        url: `organizations`,
        method: 'POST',
        body: organization,
      }),
      invalidatesTags: [{ type: 'Organizations', id: 'LIST' }],
    }),
    editOrganization: build.mutation<OrganizationNode, { organization: CreateOrganizationParams; alias: string }>({
      query: ({ organization, alias }) => ({
        url: `organizations/${alias}`,
        method: 'PATCH',
        body: organization,
      }),
      invalidatesTags: (result) => [
        { type: 'Organizations', id: 'LIST' },
        { type: 'Organizations', id: result?.alias },
      ],
    }),
    getOrganizationPaymentSchedule: build.query<
      PaymentPlan & PaymentSettingsWithMetadata,
      GetOrganizationPaymentSchedule
    >({
      query: ({ alias }) => ({
        url: `organizations/${alias}/payment-plan`,
      }),
    }),
    putOrganizationPaymentSetting: build.mutation<PaymentSettingsWithMetadata, PutOrganizationPaymentSetting>({
      query: ({ alias, paymentSettings }) => ({
        url: `organizations/${alias}/payment-setting`,
        method: 'PUT',
        body: paymentSettings,
      }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationPaymentSettings', id: alias }],
    }),
    getOrganizationPaymentSettings: build.query<PaymentSettingsWithMetadata, GetOrganizationPaymentSetting>({
      query: ({ alias }) => ({
        url: `organizations/${alias}/payment-settings`,
      }),
      providesTags: (_result, _error, { alias }) => [{ type: 'OrganizationPaymentSettings', id: alias }],
    }),
    getOrganizationPaymentAccounts: build.query<FinancialAccountDocument[], GetOrganizationPaymentSchedule>({
      query: ({ alias }) => ({
        url: `organizations/${alias}/payment-accounts`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'OrganizationPaymentAccounts', id: arg.alias }],
    }),
    getOrganizationEmailSettings: build.query<{ emailSettings: EmailSetting[] }, GetOrganizationEmailSettings>({
      query: ({ alias }) => ({
        url: `organizations/${alias}/email-settings`,
      }),
    }),
    putOrganizationEmailSettings: build.mutation<
      { emailSettings: EmailSetting[] },
      { alias: string; emailSettings: EmailSetting[] }
    >({
      query: ({ alias, emailSettings }) => ({
        url: `organizations/${alias}/email-settings`,
        method: 'PUT',
        body: { emailSettings },
      }),
    }),
    getOrganizationSplitPaySettings: build.query<OrganizationCEDSettings, GetOrganizationSplitPaySettings>({
      query: ({ alias }) => ({
        url: `organizations/${alias}/split-pay-settings`,
      }),
      providesTags: (_result, _error, { alias }) => [{ type: 'OrganizationSplitPaySetting', id: alias }],
    }),
    getSplitPayProgramNames: build.query<SplitPayProgramNamesResponse, void>({
      query: () => ({
        url: `organizations/split-pay-settings/program-names`,
      }),
    }),
    putOrganizationSplitPaySettings: build.mutation<
      OrganizationCEDSettings,
      { alias: string; splitPaySettings: OrganizationCEDSettings }
    >({
      query: ({ alias, splitPaySettings }) => ({
        url: `organizations/${alias}/split-pay-settings`,
        method: 'PUT',
        body: { ...splitPaySettings },
      }),
      invalidatesTags: (_result, _error, { alias }) => [{ type: 'OrganizationSplitPaySetting', id: alias }],
    }),
    updateBankAccount: build.mutation<
      FinancialAccountRegister,
      { paymentAccount: FinancialAccountRegister; alias: string }
    >({
      query: ({ paymentAccount, alias }) => ({
        url: `organizations/${alias}/payment-account`,
        method: 'PUT',
        body: paymentAccount,
      }),
      invalidatesTags: (_result, error, arg) =>
        error ? [] : [{ type: 'OrganizationPaymentSettings', id: arg?.alias }],
    }),
    getOrgPriceSheetMappings: build.query<PriceSheetMapping<any>[], { alias: string; id?: string }>({
      query: ({ alias, id }) => ({
        url: `organizations/${alias}/pricing/mappings${id ? `/${id}` : ''}`,
      }),
      transformResponse(response: PriceSheetMapping<any>[]) {
        return response?.toSorted((a: any, b: any) => {
          const stateA = a.state.toLowerCase();
          const stateB = b.state.toLowerCase();

          if (stateA < stateB) {
            return -1;
          }
          if (stateA > stateB) {
            return 1;
          }
          return 0;
        });
      },
      providesTags: (_result, _error, { alias, id }) => [
        { type: 'OrganizationPriceSheetMappings', id: alias, mappingId: id },
      ],
    }),
    searchUsers: build.query<SearchUsersResponse, SearchUsersParams>({
      query: ({ orgAlias, searchUsers, page = 1, limit = 10 }) => {
        let queryString = `organizations/${orgAlias}/users?search=${searchUsers}&page=${page}&limit=${limit}`;
        return { url: queryString };
      },
      providesTags: (_result, _error, { orgAlias }) => [{ type: 'Users', id: orgAlias }],
    }),
    getOrganizationListFilters: build.query<any, Record<any, any>>({
      query: () => {
        return {
          url: `organizations/filters`,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    searchOrganizationsByUser: build.query<
      { organizations: OrganizationNode[] },
      { search: string; page?: number; limit?: number }
    >({
      query: ({ search, page = 1, limit = 10 }) => {
        return {
          url: `organizations/search/by-user?search=${search}&page=${page}&limit=${limit}`,
        };
      },
      transformResponse: (response: { organizations: OrganizationNode[] }) => {
        return { organizations: response.organizations };
      },
      providesTags: (_result, _error, { search }) => [{ type: 'OrganizationsByUser', id: search }],
    }),
  }),
});

export const {
  useGetOrganizationTreeQuery,
  useLazyGetOrganizationTreeQuery,
  useGetOrganizationDetailsQuery,
  useCreateOrganizationMutation,
  useEditOrganizationMutation,
  useGetOrganizationPaymentScheduleQuery,
  useGetOrganizationEmailSettingsQuery,
  usePutOrganizationEmailSettingsMutation,
  useGetSplitPayProgramNamesQuery,
  useGetOrganizationSplitPaySettingsQuery,
  usePutOrganizationSplitPaySettingsMutation,
  useUpdateBankAccountMutation,
  useGetOrgPriceSheetMappingsQuery,
  useLazyGetOrgPriceSheetMappingsQuery,
  useSearchUsersQuery,
  usePutOrganizationPaymentSettingMutation,
  useGetOrganizationPaymentSettingsQuery,
  useGetOrganizationPaymentAccountsQuery,
  useGetOrganizationListFiltersQuery,
  useLazySearchOrganizationsByUserQuery,
} = organizationApi;
