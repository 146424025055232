import { Helmet } from 'react-helmet';
import { Box, Button } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { MainContainer } from '@/components';
import { useInviteToken } from '@/components/auth/useInviteToken';
import { useGetAccountWithTokenQuery } from '@/services/accounts';

export function SalesRepAuthentication() {
  const { id } = useParams<{ id: any }>();
  const { inviteToken } = useInviteToken();

  const { data: account, isLoading: isAccountLoading }: any = useGetAccountWithTokenQuery({
    id,
    inviteToken,
  });

  const handleClick = async () => {
    const queryParams = new URLSearchParams();
    queryParams.append('state', JSON.stringify({ returnTo: window.location.href, inviteToken }));
    queryParams.append('inviteToken', inviteToken || '');

    const url = `/api/accounts/${account?.id}/sales-rep-verification/verify?${new URLSearchParams(queryParams)}`;
    window.location.assign(url);
  };

  return (
    <>
      <Helmet>
        <title>Verify your Identity</title>
      </Helmet>
      <MainContainer>
        <Box width="50">
          <PageHeader title={`Hello${account?.salesRepName ? ', ' + account?.salesRepName : ''}!`} />
          <Box gap="lg">
            <Box
              fontSize={{
                base: 'sm',
                tablet: 'md',
                desktop: 'lg',
              }}
              color="body-secondary"
            >
              Thank you for on-boarding as a sales representative with Palmetto Finance.
            </Box>
            {account && (
              <Box>
                <Box fontWeight="bold">Your email:</Box>
                <Box>{account?.salesRepEmail}</Box>
              </Box>
            )}
            <Box width={{ base: '100', tablet: '25' }}>
              <Button onClick={handleClick} disabled={isAccountLoading || !account}>
                Verify identity
              </Button>
            </Box>
          </Box>
        </Box>
      </MainContainer>
    </>
  );
}
