import { Box, Button, Modal, ModalProps } from '@palmetto/palmetto-components';

export interface DomesticContentAttestmentModalProps extends ModalProps {
  onAttestmentConfirmed: () => Promise<void>;
  confirmationLabel: string;
  confirmationDescription: string;
  isLoading: boolean;
}

export function DomesticContentAttestmentModal({
  isOpen,
  onDismiss,
  onAttestmentConfirmed,
  confirmationLabel,
  confirmationDescription,
  isLoading,
}: DomesticContentAttestmentModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth="4xl" ariaLabelledBy="domesticContentAttestation">
      <Modal.Header id="domesticContentAttestation" title={'Domestic Content Attestation'} onDismiss={onDismiss} />
      <Modal.Body background="secondary" childGap="lg">
        <Box fontSize={'sm'} fontWeight="bold" data-testid="dc-confirmation-modal-label">
          <Box>{confirmationLabel}</Box>
        </Box>
        <Box fontSize={'sm'} color="body-primary" data-testid="dc-confirmation-modal-description">
          <Box>{confirmationDescription}</Box>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          tone="neutral"
          onClick={onDismiss}
          id="cancelAttestmentModal"
          disabled={isLoading}
        >
          <Box>Cancel</Box>
        </Button>
        <Button
          variant="primary"
          tone="primary"
          onClick={onAttestmentConfirmed}
          id="confirmAttestmentModal"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          <Box>Proceed</Box>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
