import { useGetSalesRepQuery, useUpdateSalesRepMutation } from '@/services/salesReps';
import { Link, NavLink, useParams } from 'react-router-dom';
import { MainContainer } from '../MainContainer';
import {
  Badge,
  Box,
  Button,
  Card,
  DimensionSize,
  Spinner,
  toast,
  useOpenClose,
} from '@palmetto/palmetto-components';
import DateTimeDisplay from '../DateTime';
import { ListItemElement } from '../Quotes/QuoteListItem.utils';
import RequirePermissions from '../auth/requirePermissions';
import { SalesRepStatus } from 'types';
import { SalesRepActionModal } from './SalesRepActionModal';
import { useState } from 'react';

export const SalesRepDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSalesRepQuery({ id: id as string }, { skip: !id });
  const [updateSalesRep, { isLoading: isUpdatingSalesRep }] = useUpdateSalesRepMutation();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [salesRep, setSalesRep] = useState<any>(data);

  const handleOpenModal = (newStatus: string) => {
    const actionToPerform = newStatus === SalesRepStatus.active ? 'unblock' : 'block';
    setSalesRep({ ...data, actionToPerform, newStatus });
    openModal();
  };
  const handleCloseModal = () => {
    setSalesRep(undefined);
    closeModal();
  };

  const handleUpdateStatus = async () => {
    const toastId = toast.loading(
      `${salesRep.actionToPerform.charAt(0).toUpperCase() + salesRep.actionToPerform.slice(1)}ing sales rep...`,
    );

    try {
      await updateSalesRep({ id: salesRep.id, status: salesRep.newStatus });
    } catch (e: any) {
      console.error(e);
      toast.error(e?.data?.message || `An error occurred while ${salesRep.actionToPerform}ing the sales rep.`);
    } finally {
      toast.dismiss(toastId);
      handleCloseModal();
    }
  };

  return (
    <MainContainer>
      <Box style={{ marginLeft: '0' }}>
        <NavLink to={`/admin/sales-reps`} style={{ textDecoration: 'none', fontWeight: '500' }} end>
          {({ isActive }) => (
            <Box
              as="span"
              className={isActive ? 'tab-active' : ''}
              hover={{ color: 'primary' }}
              style={{ transitionDuration: '0s' }}
            >
              Sales Reps
            </Box>
          )}
        </NavLink>
      </Box>
      {isLoading ? (
        <Box
          display="block"
          textAlign="center"
          padding="lg"
          childGap="xl"
          borderColor="separator"
          borderWidth="xs 0 0 0"
        >
          <Spinner size="lg" />
        </Box>
      ) : (
        <Box display="flex" direction={{ desktop: 'row', base: 'column' }} gap="4xl">
          <Card
            width={{
              desktop: '50',
              tablet: '100',
            }}
          >
            <Card.Header childGap="lg">
              <Box alignContent="center">
                <img src={data?.idProviderUser?.photoUrl} alt={data?.idProviderUser?.displayName} />
              </Box>
            </Card.Header>
            <Card.Section>
              <Badge
                variant={data?.status === 'active' ? 'success' : data?.status === 'blocked' ? 'danger' : 'warning'}
                message={data?.status}
              />
            </Card.Section>
            <Card.Section>
              <Box direction="column" childGap="2xs">
                <Box as="h1" fontSize="md">
                  {data?.idProviderUser?.displayName}
                </Box>
                {data?.idProviderUser?.displayName !== data?.idProviderUser?.legalName ? (
                  <Box as="p" fontSize="xs">
                    Legal Name: {data?.idProviderUser?.legalName}
                  </Box>
                ) : null}
                <Box as="p" fontSize="xs">
                  ReCheck Id: {data?.idProviderUser?.idProviderId}
                </Box>
              </Box>
            </Card.Section>
            <Card.Section childGap="md">
              <Box>
                <Box fontWeight="medium">ReCheck Verification:</Box>{' '}
                <DateTimeDisplay
                  value={data?.idProviderUser?.idProviderVerificationDate as any}
                  displayLocalDateTime
                  dateFormat="MM-dd-yyyy h:mm a"
                />
              </Box>
              <Box>
                <Box fontWeight="medium">Last Authenticated On:</Box>{' '}
                <DateTimeDisplay
                  value={data?.authenticatedOn as any}
                  displayLocalDateTime
                  dateFormat="MM-dd-yyyy h:mm a"
                />
              </Box>
            </Card.Section>
            <Card.Footer>
              {data?.status !== SalesRepStatus.blocked ? (
                <RequirePermissions permissions={['reCheckAdmin']}>
                  <Button
                    iconPrefix="ban"
                    size="sm"
                    variant="secondary"
                    tone="danger"
                    onClick={() => handleOpenModal(SalesRepStatus.blocked)}
                  >
                    Block
                  </Button>
                </RequirePermissions>
              ) : null}
              {data?.status !== 'active' ? (
                <RequirePermissions permissions={['reCheckAdmin']}>
                  <Button
                    iconPrefix="checkbox-btn-checked"
                    size="sm"
                    variant="secondary"
                    onClick={() => handleOpenModal(SalesRepStatus.active)}
                  >
                    Activate
                  </Button>
                </RequirePermissions>
              ) : null}
            </Card.Footer>
          </Card>
          <Box direction="column" childGap="md" width="6xl">
            <Card>
              <Card.Section childGap="md">
                <Box as="h2" fontSize="lg">
                  ReCheck Emails
                </Box>
                <Box padding="0 0 lg 0">
                  {data?.idProviderUser?.emails?.map((email: string) => <ListItemDisplay value={email} />)}
                </Box>
              </Card.Section>
            </Card>
            <Card>
              <Card.Section childGap="md">
                <Box as="h2" fontSize="lg">
                  LightReach Emails
                </Box>
                {data?.accountEmails?.map((email) => <ListItemDisplay value={email} />)}
              </Card.Section>
            </Card>
            <Card>
              <Card.Section childGap="md">
                <Box as="h2" fontSize="lg">
                  LightReach Organizations
                </Box>
                {data?.organizationIds?.map((org) => <ListItemDisplay value={org} />)}
              </Card.Section>
            </Card>
            <Card>
              <Card.Section childGap="md">
                <Box as="h2" fontSize="lg">
                  LightReach Accounts
                </Box>
                {data?.accountIds?.map((accountId) => (
                  <ListItemDisplay value={accountId} link={`/accounts/${accountId}`} />
                ))}
              </Card.Section>
            </Card>
          </Box>
        </Box>
      )}
      <SalesRepActionModal
        isModalOpen={isModalOpen}
        salesRep={salesRep}
        handleCloseModal={handleCloseModal}
        handleUpdateStatus={handleUpdateStatus}
        isLoading={isLoading || isUpdatingSalesRep}
      />
    </MainContainer>
  );
};

const ListItemDisplay = ({ value, link }: { value?: string; link?: string }) => (
  <Box direction="row" padding="0 lg" gap="5xl">
    <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
      {link ? <Link to={link}>{value}</Link> : <Box>{value}</Box>}
    </Box>
  </Box>
);
